import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MobileContext } from "../../../providers/MobileContext";
import styles from "./Hospital.module.scss";
import Wrapper from "../../../Components/Layout/Wrapper";
import BasicAPI from "../../../API/BasicAPI";

const Hospital = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [hospitalList, setHospitalList] = useState([]);
  const isMobile = useContext(MobileContext);

  const moveListPage = (e, categoryName) => {
    e.preventDefault();

    const params = {
      category: categoryName,
      keyword: "",
      sort: "popular",
    };

    navigate(`/partners`, {
      state: params,
    });
  };

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/partner/partners/by_category/`,
      success: (res) => setHospitalList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    hospitalList.length !== 0 && (
      <div className={styles.hospital}>
        <Wrapper>
          {hospitalList.map((item) => (
            <div className={styles.category_wrapper} key={item.id}>
              <div className={styles.name_wrapper}>
                <div className={styles.category_name}>
                  Top of <span>{item.category_name}</span> in South Korea
                </div>
                <Link
                  className={styles.more_btn}
                  onClick={(e) => moveListPage(e, item.category_name)}
                >
                  {t`main.more`}
                </Link>
              </div>
              <div
                className={styles.thumbnail_wrapper}
                style={{ flexDirection: isMobile ? "column" : "row" }}
              >
                {item.category_list.map((hospital) => (
                  <Link to={`/partner/${hospital.id}`} key={hospital.id}>
                    <img src={hospital.thumbnail} alt="병원 썸네일" />
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </Wrapper>
      </div>
    )
  );
};

export default Hospital;
