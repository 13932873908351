import { Link } from "react-router-dom";
import styles from "./Gallery.module.scss";
import { useContext } from "react";
import { MobileContext } from "../../providers/MobileContext";

const Gallery = ({
  category,
  itemList,
  column = 3,
  columnGap = "3rem",
  rowGap = "3rem",
}) => {
  const isMobile = useContext(MobileContext);

  return (
    <div
      className={styles.wrapper}
      style={{
        gridTemplateColumns: isMobile ? "unset" : `repeat(${column}, 1fr)`,
        columnGap: columnGap,
        rowGap: rowGap,
      }}
    >
      {itemList.map((item) => (
        <Link
          to={`/${category}/${item.id}`}
          className={styles.gallery_item}
          key={item.id}
          style={{ borderBottom: isMobile ? "0.1rem solid #ddd" : "" }}
        >
          <div className={styles.thumbnail}>
            <img src={item.thumbnail} alt="썸네일" />
          </div>
          <div className={styles.title}>{item.title}</div>
        </Link>
      ))}
    </div>
  );
};

export default Gallery;
