import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MobileContext } from "../../../providers/MobileContext";
import styles from "./Advertisement.module.scss";
import Wrapper from "../../../Components/Layout/Wrapper";
import advertisement_1 from "../../../Assets/Images/advertisement_1.png";
import advertisement_2 from "../../../Assets/Images/advertisement_2.png";
import advertisement_3 from "../../../Assets/Images/advertisement_3.png";
import Button from "../../../Components/Button/Button";

const Advertisement = () => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const navigate = useNavigate();

  return (
    <div className={styles.advertisement}>
      <Wrapper>
        <div className={styles.title_center}>{t(`main.sign_up`)}</div>
        <div
          className={styles.box_wrapper}
          style={{ flexDirection: isMobile ? "column" : "row" }}
        >
          <div className={styles.box}>
            <div className={styles.bg_wrapper}>
              <img src={advertisement_1} alt="광고 배너 이미지" />
            </div>
            <div className={styles.text_wrapper}>
              <p className={styles.top_text}>{t(`main.regular_advertorial`)}</p>
              <p className={styles.content_text}>
                {t(`main.regular_advertorial_description`)}
              </p>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.bg_wrapper}>
              <img src={advertisement_2} alt="광고 배너 이미지" />
            </div>
            <div className={styles.text_wrapper}>
              <p className={styles.top_text}>{t(`main.banner_management`)}</p>
              <p className={styles.content_text}>
                {t(`main.banner_management_description`)}
              </p>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.bg_wrapper}>
              <img src={advertisement_3} alt="광고 배너 이미지" />
            </div>
            <div className={styles.text_wrapper}>
              <p className={styles.top_text}>{t(`main.content_management`)}</p>
              <p className={styles.content_text}>
                {t(`main.content_management_description`)}
              </p>
            </div>
          </div>
        </div>
        <Button
          type="blue"
          text={t(`main.enrollment`)}
          onClick={() => navigate("/register_partner")}
        />
      </Wrapper>
    </div>
  );
};

export default Advertisement;
