import { useParams } from "react-router-dom";
import SubPage from "../SubPage/SubPage";

const ColumnItemPage = () => {
  const { id } = useParams();

  return (
    <SubPage
      url={`${process.env.REACT_APP_SERVICE_URL}/article/columns/${id}/`}
    />
  );
};

export default ColumnItemPage;
