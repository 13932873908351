import { useParams } from "react-router-dom";
import SubPage from "../../pages/SubPage/SubPage";

const EventItemPage = () => {
  const { id } = useParams();

  return <SubPage url={`${process.env.REACT_APP_SERVICE_URL}/board/${id}`} />;
};

export default EventItemPage;
