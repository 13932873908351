import { useEffect, useState } from "react";
import Youtube from "../../../Components/Youtube/Youtube";
import styles from "../PartnerHomePage.module.scss";
import BasicAPI from "../../../API/BasicAPI";

const YoutubeContent = ({ sectionRef, partnerInfo }) => {
  const [youtubeChannelInfo, setYoutubeChannelInfo] = useState("");
  const [youtubeMainVideo, setYoutubeMainVideo] = useState("");
  const [youtubeVideoList, setYoutubeVideoList] = useState("");

  useEffect(() => {
    //채널정보 호출
    BasicAPI({
      url: "https://www.googleapis.com/youtube/v3/channels",
      method: "GET",
      data: {
        id: partnerInfo.youtube.user_id,
        key: process.env.REACT_APP_GOOGLE_KEY,
        part: "snippet, statistics",
        fields:
          "items(snippet(title, description, customUrl, thumbnails(high(url))), statistics(viewCount, subscriberCount, videoCount))",
      },
      success: (res) => setYoutubeChannelInfo(res.items[0]),
      fail: (res) => console.error(res),
    });

    //메인영상 호출
    //메인영상이 지정되지 않은 경우 조회수가 가장 높은 영상 호출
    if (!partnerInfo.youtube.main_video_url) {
      BasicAPI({
        url: "https://www.googleapis.com/youtube/v3/search",
        method: "GET",
        data: {
          channelId: partnerInfo.youtube.user_id,
          key: process.env.REACT_APP_GOOGLE_KEY,
          part: "snippet",
          order: "viewCount",
          maxResults: 1,
        },
        success: (res) => setYoutubeMainVideo(res.items[0]),
        fail: (res) => console.error(res),
      });
    } else {
      BasicAPI({
        url: "https://www.googleapis.com/youtube/v3/videos",
        method: "GET",
        data: {
          id: partnerInfo.youtube.main_video_url,
          key: process.env.REACT_APP_GOOGLE_KEY,
          part: "snippet",
        },
        success: (res) => setYoutubeMainVideo(res.items[0]),
        fail: (res) => console.error(res),
      });
    }

    //최신영상 호출
    BasicAPI({
      url: "https://www.googleapis.com/youtube/v3/search",
      method: "GET",
      data: {
        channelId: partnerInfo.youtube.user_id,
        key: process.env.REACT_APP_GOOGLE_KEY,
        part: "snippet",
        order: "date",
        maxResults: 6,
      },
      success: (res) => setYoutubeVideoList(res.items),
      fail: (res) => console.error(res),
    });
  }, [partnerInfo]);

  return (
    <div
      className={styles.content}
      ref={(el) => (sectionRef.current["youtube"] = el)}
    >
      <div className={styles.title}>Youtube</div>
      <Youtube
        channelInfo={youtubeChannelInfo}
        mainVideo={youtubeMainVideo}
        videoList={youtubeVideoList}
      />
    </div>
  );
};

export default YoutubeContent;
