import styles from "../PartnerHomePage.module.scss";
import GoogleMap from "../../../Components/GoogleMap/GoogleMap";

const Intro = ({ partnerInfo, sectionRef }) => {
  return (
    <div
      ref={(el) => (sectionRef.current["intro"] = el)}
      className={styles.content}
    >
      <div className={styles.title}>Intro</div>
      <div className={styles.text}>{partnerInfo.intro.description}</div>
      <GoogleMap
        locations={partnerInfo.sns.google ? [partnerInfo] : []}
        mapsize={50}
        zoom={18}
        margin={[3, 0, 0, 0]}
      />
    </div>
  );
};

export default Intro;
