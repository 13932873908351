import { Link } from "react-router-dom";
import { useContext, useRef } from "react";
import { MobileContext } from "../../providers/MobileContext";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import i18n from "../../locales/i18n";
import styles from "./ButtonWrapper.module.scss";
import homepage_icon from "../../Assets/Images/home_icon.png";
import contact_icon from "../../Assets/Images/contact_icon.png";

const ButtonWrapper = ({ contactList, setPopupOpened }) => {
  const { t } = useTranslation();
  const { website, email } = contactList;
  const isMobile = useContext(MobileContext);

  const partnerRef = useRef(null);

  const openContactPopup = (e) => {
    e.preventDefault();
    setPopupOpened(true);
    document.documentElement.style.overflow = "hidden";
  };

  return (
    <div
      className={styles.contact}
      style={{ paddingTop: isMobile ? "2rem" : "0" }}
      ref={partnerRef}
    >
      {website && (
        <Link
          to={contactList.website}
          target="_blank"
          style={{
            width: isMobile ? "100%" : "auto",
            justifyContent: isMobile ? "center" : "space-between",
          }}
          className={styles.website_btn}
        >
          <img src={homepage_icon} alt="홈페이지 이동" />
          <p>{t(`partners.homepage`)}</p>
        </Link>
      )}
      {email && (
        <Link
          style={{
            width: isMobile ? "100%" : "auto",
            justifyContent: isMobile ? "center" : "space-between",
          }}
          className={styles.contact_btn}
          onClick={openContactPopup}
        >
          <img src={contact_icon} alt="문의하기" />
          <p>{t(`partners.inquire`)}</p>
        </Link>
      )}
    </div>
  );
};

export default ButtonWrapper;
