import $ from "jquery";

const BasicAPI = ({ url, method, data, success, fail }) => {
  const requestUrl = url.startsWith("http")
    ? url
    : `${process.env.REACT_APP_SERVICE_URL}/${url}`;

  $.ajax({
    url: requestUrl,
    method: method,
    data: data,
  })
    .done((res) => success(res))
    .fail((res) => fail(res));
};

export default BasicAPI;
