import { Link } from "react-router-dom";
import styles from "../PartnerList/PartnerList.module.scss";
import ContactWrapper from "../ContactWrapper/ContactWrapper";
import defaultImage from "../../Assets/Images/logo01.png";

const GridViewMode = ({ partner }) => {
  return (
    <div className={styles.list_view_clicked}>
      <Link to={`/partner/${partner.id}`} className={styles.link_wrapper}>
        {partner.thumbnail ? (
          <div className={styles.partner_img}>
            <img src={partner.thumbnail} alt="입점기업 메인 배너" />
          </div>
        ) : (
          <div className={styles.partner_img}>
            <img src={defaultImage} alt="기본 이미지" />
          </div>
        )}

        <div className={styles.partner_title}>{partner.company_name}</div>
      </Link>
      <ContactWrapper
        partnerInfo={partner}
        iconSize={3.6}
        snsGap={1.2}
        padding={[1, 0]}
      />
    </div>
  );
};

export default GridViewMode;
