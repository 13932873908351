import Layout from "../../Components/Layout/Layout";
import Search from "../../Components/Search/Search";
import Advertisement from "./Advertisement/Advertisement";
import PopularNews from "./PopularNews/PopularNews";
import Hospital from "./Hospital/Hospital";
import Content from "./Content/Content";
import ScrollTopButton from "../../Components/Button/ScrollTopButton";

const MainPage = () => {
  return (
    <Layout>
      <Search />
      <PopularNews />
      <Hospital />
      <Advertisement />
      <Content />
      <ScrollTopButton />
    </Layout>
  );
};

export default MainPage;
