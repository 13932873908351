import { useContext } from "react";
import Button from "../Button/Button";
import styles from "./Form.module.scss";
import { MobileContext } from "../../providers/MobileContext";

const Form = ({ title, btnText, onClick, children }) => {
  const isMobile = useContext(MobileContext);

  return (
    <form>
      <div className={styles.title_wrapper}>
        <p>{title}</p>
      </div>
      <div
        className={styles.form_wrapper}
        style={{ border: isMobile && "none", padding: isMobile && "0 1rem" }}
      >
        {children}
        <div className={styles.btn_wrapper}>
          <Button type="blue" text={btnText} onClick={onClick} />
        </div>
      </div>
    </form>
  );
};

export default Form;
