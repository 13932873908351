import { useEffect, useState } from "react";
import styles from "./UserProfile.module.scss";

const UserProfile = ({ isMobile, channelInfo }) => {
  const [infoSnippet, setInfoSnippet] = useState({
    title: "",
    customUrl: "",
    description: "",
    thumbnails: "",
  });
  const [infoSubscriberCount, setInfoSubscriberCount] = useState({
    subscriberCount: "",
    videoCount: "",
    viewCount: "",
  });

  const calCount = (num) => {
    if (num < 1000) {
      return num;
    } else if (num < 1000000) {
      return Math.round(num / 1000) + "K";
    } else {
      return Math.round(num / 1000000) + "M";
    }
  };

  useEffect(() => {
    if (!channelInfo.snippet) {
      return;
    }

    setInfoSnippet({
      title: channelInfo.snippet.title,
      customUrl: channelInfo.snippet.customUrl,
      description: channelInfo.snippet.description,
      thumbnails: channelInfo.snippet.thumbnails,
    });

    setInfoSubscriberCount({
      subscriberCount: channelInfo.statistics.subscriberCount,
      videoCount: channelInfo.statistics.videoCount,
      viewCount: channelInfo.statistics.viewCount,
    });
  }, [channelInfo]);

  return (
    infoSnippet.customUrl !== "" && (
      <a
        className={styles.youtube_profile_wrapper}
        href={`https://www.youtube.com/${infoSnippet.customUrl}`}
        target="_blank"
        rel="noreferrer"
        style={{ flexDirection: isMobile ? "column" : " row" }}
      >
        <img
          src={infoSnippet.thumbnails.high.url}
          alt="유튜브 채널 프로필 이미지"
        />
        <div className={styles.profile_info_wrapper}>
          <div
            className={styles.user_title}
            style={{
              textAlign: isMobile ? "center" : "left",
              paddingTop: isMobile ? "2rem" : "0",
            }}
          >
            {infoSnippet.title}
          </div>
          <div
            className={styles.user_info_wrapper}
            style={{ justifyContent: isMobile ? "center" : "left" }}
          >
            <div>
              {calCount(infoSubscriberCount.subscriberCount)} Subscribers
            </div>
            <div>{calCount(infoSubscriberCount.videoCount)} Videos</div>
            <div>{calCount(infoSubscriberCount.viewCount)} Views</div>
          </div>
          <div className={styles.user_description}>
            {infoSnippet.description}
          </div>
        </div>
      </a>
    )
  );
};

export default UserProfile;
