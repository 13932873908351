import { useTranslation } from "react-i18next";
import styles from "./CompanyPage.module.scss";
import Layout from "../../Components/Layout/Layout";
import Wrapper from "../../Components/Layout/Wrapper";
import CompanyTitle from "../../Components/Title/CategoryTitle";

const CompanyPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Wrapper>
        <CompanyTitle
          title={t(`company.company`)}
          description={t(`company.description`)}
        />
      </Wrapper>
    </Layout>
  );
};

export default CompanyPage;
