import { useContext, useEffect, useState } from "react";
import { MobileContext } from "../../../../providers/MobileContext";
import styles from "../PopularNews.module.scss";
import NewsSwiper from "../../../../Components/Swiper/NewsSwiper";
import BasicAPI from "../../../../API/BasicAPI";

const LatestNews = () => {
  const isMobile = useContext(MobileContext);
  const [latestArticleList, setLatestArticleList] = useState([]);

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/article/articles/latest/`,
      success: (res) => setLatestArticleList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <div
      className={styles.latest_wrapper}
      style={{ width: isMobile ? "100%" : "45rem" }}
    >
      <NewsSwiper swiperList={latestArticleList} />
    </div>
  );
};

export default LatestNews;
