import styles from "./Gallery.module.scss";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import closeBtn from "../../Assets/Images/close_icon_white.png";

const Gallery = ({ imgList, setGalleryOpened, startIndex }) => {
  const closePopup = () => {
    setGalleryOpened(false);
    document.documentElement.style.overflow = "scroll";
  };

  return (
    <div
      className={styles.popup_window}
      style={{ top: window.scrollY, left: 0 }}
    >
      <div className={styles.close_btn}>
        <img src={closeBtn} alt="팝업창 닫기" onClick={closePopup} />
      </div>
      <div className={styles.gallery_popup}>
        <ImageGallery
          items={imgList}
          startIndex={startIndex}
          showFullscreenButton={false}
          showPlayButton={false}
          showIndex
        />
      </div>
    </div>
  );
};

export default Gallery;
