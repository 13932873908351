import { useEffect, useState } from "react";
import SwiperSlider from "../Swiper/SwiperSlider";
import BasicAPI from "../../API/BasicAPI";

const AdBannerComponent = ({ targetCode, borderRadius, sliderHeight = 5 }) => {
  const [adList, setAdList] = useState([]);

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/ads/?target_section=${targetCode}`,
      success: (res) => setAdList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <SwiperSlider
      sliderHeight={sliderHeight}
      borderRadius={borderRadius}
      swiperList={adList}
      hidePagination={false}
    />
  );
};

export default AdBannerComponent;
