const CheckUsername = (username) => /^[A-Za-z0-9]{4,12}$/.test(username);

const CheckPassword = (password) =>
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
    password
  );

const CheckEmail = (userEmail) =>
  /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/.test(userEmail);

const CheckBirthDate = (userBirthDate) =>
  /^(19\d{2}|20\d{2})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/.test(userBirthDate);

const CheckWordLength = (word, length) => word.length === length;

export {
  CheckUsername,
  CheckPassword,
  CheckEmail,
  CheckBirthDate,
  CheckWordLength,
};

/* 
    유효성 조건
    1. 아이디
        1. 4글자 이상 또는 12글자 이하
        2. 영어 또는 숫자가 들어간 경우
    2. 비밀번호
        1. 최소 8글자 이상
        2. 알파벳, 숫자, 특수문자 하나 이상 
    3. 이메일
        1. @ 기준 앞 구간, 뒷 구간이 알파벳 or 숫자 조합으로 이루어져 있는지 체크
        2. @ 기준 뒷 구간에서 . 뒷 구간이 알파벳 or 숫자 조합으로 이루어져 있는지 체크
    4. 생년월일
        1. 8자리인지 체크
        2. 유효한 숫자인지 체크

*/
