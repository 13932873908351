import { useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import BasicAPI from "../../API/BasicAPI";

const PolicyPage = () => {
  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/config/privacy`,
      success: (res) => console.log(res),
      fail: (res) => console.error(res),
    });
  }, []);

  return <Layout>Policy</Layout>;
};

export default PolicyPage;
