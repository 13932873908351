import { useParams } from "react-router-dom";
import SubPage from "../SubPage/SubPage";

const ArticlePage = () => {
  const { id } = useParams();

  return (
    <SubPage
      search
      url={`${process.env.REACT_APP_SERVICE_URL}/article/articles/${id}`}
    />
  );
};

export default ArticlePage;
