import { createContext, useEffect, useState } from "react";

const MobileContext = createContext();

const MobileProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    if (window.outerWidth <= 768) {
      setIsMobile("mobile");
    } else if (window.outerWidth <= 1024 && window.outerWidth > 768) {
      setIsMobile("tablet");
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    checkMobile();
    const eventId = window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", eventId);
    };
  }, []);

  return (
    <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>
  );
};

export { MobileContext, MobileProvider };
