import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BasicAPI from "../../../../API/BasicAPI";
import styles from "../Content.module.scss";

const ColumnContent = ({ isMobile, movePage }) => {
  const { t } = useTranslation();
  const [columnList, setColumnList] = useState([]);

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/article/columns/`,
      success: (res) => setColumnList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <div
      className={styles.column}
      style={{ width: isMobile ? "100%" : "46rem" }}
    >
      <div className={styles.title_wrapper}>
        <div className={styles.title}>{t`header.column`}</div>
        <Link
          className={styles.more_btn}
          onClick={(e) => movePage(e, "column")}
        >
          {t`main.more`}
        </Link>
      </div>
      <div className={styles.board_wrapper}>
        {columnList.map((item) => (
          <Link
            key={item.id}
            to={`/article/columns/${item.id}`}
            className={styles.board}
          >
            <div className={styles.thumbnail}>
              <img
                src={item.column_writer.thumbnail}
                alt="칼럼 기고자 프로필 이미지"
              />
            </div>
            <div className={styles.text_wrapper}>
              <div className={styles.series_name}>
                {item.column_series.title}
              </div>
              <div className={styles.title}>{item.title}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ColumnContent;
