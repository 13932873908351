import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../Components/Layout/Layout";
import Wrapper from "../../Components/Layout/Wrapper";
import CategoryTitle from "../../Components/Title/CategoryTitle";
import BasicAPI from "../../API/BasicAPI";
import Table from "../../Components/Table/Table";
import CategorySearch from "../../Components/Search/CategorySearch";
import CustomPagination from "../../Components/Pagination/CustomPagination";

const EventPage = () => {
  const { t } = useTranslation();
  const tableHeader = ["번호", "제목", "작성일자"];
  const searchFilterList = [
    { value: "title", text: "제목" },
    { value: "content", text: "내용" },
  ];
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/board/?category=event`,
      success: (res) => setEventList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <Layout>
      <Wrapper>
        <CategoryTitle
          title={t(`event.event`)}
          description={t(`event.description`)}
          padding={`3rem 0 0 0`}
        />
        <CategorySearch
          commonCategory={`board`}
          category={`event`}
          filterList={searchFilterList}
          setContentList={setEventList}
        />
        <Table
          category="event"
          tableHeader={tableHeader}
          tableContent={eventList}
          cellWidth={"10% 70% 20%"}
        />
        <CustomPagination
          totalItemNum={5}
          itemsCountPerPage={5}
          pageRangeDisplayed={10}
          padding="1rem"
        />
      </Wrapper>
    </Layout>
  );
};

export default EventPage;
