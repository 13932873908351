import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { MobileProvider } from "../providers/MobileContext";
import styles from "../App.module.scss";
import MainPage from "../pages/main/MainPage";
import PartnersPage from "../pages/partners/PartnersPage";
import RegisterPartnerPage from "../pages/registerPartner/RegisterPartnerPage";
import ContactPage from "../pages/contact/ContactPage";
import ServicePage from "../pages/service/ServicePage";
import PolicyPage from "../pages/policy/PolicyPage";
import PartnerHomePage from "../pages/PartnerHome/PartnerHomePage";
import ArticlePage from "../pages/Article/ArticlePage";
import CompanyPage from "../pages/company/CompanyPage";
import EventPage from "../pages/event/EventPage";
import ColumnPage from "../pages/column/ColumnPage";
import EventItemPage from "../pages/event/EventItemPage";
import ColumnItemPage from "../pages/column/ColumnItemPage";
import CardNewsPage from "../pages/cardnews/CardNewsPage";
import CardNewsItemPage from "../pages/cardnews/CardNewsItemPage";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => window.scrollTo(0, 0), [location]);
};

const Routers = () => {
  return (
    <div className={`${styles.app}`}>
      <MobileProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" exact element={<MainPage />} />
            <Route path="/company" element={<CompanyPage />}></Route>
            <Route path="/partners" element={<PartnersPage />} />
            <Route path="/event" element={<EventPage />}></Route>
            <Route path="/event/:id" element={<EventItemPage />}></Route>
            <Route path="/column" element={<ColumnPage />}></Route>
            <Route
              path="/article/columns/:id"
              element={<ColumnItemPage />}
            ></Route>
            <Route path="/cardnews" element={<CardNewsPage />}></Route>
            <Route path="/cardnews/:id" element={<CardNewsItemPage />}></Route>
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/register_partner" element={<RegisterPartnerPage />} />
            <Route path="/partner/:id" element={<PartnerHomePage />} />
            <Route path="/article/articles/:id" element={<ArticlePage />} />
            <Route path="/service" element={<ServicePage />} />
            <Route path="/policy" element={<PolicyPage />} />
          </Routes>
        </BrowserRouter>
      </MobileProvider>
    </div>
  );
};

export default Routers;
