import { useState } from "react";
import styles from "./CustomPagination.module.scss";
import ReactPagination from "react-js-pagination";
import firstPageBtn from "../../Assets/Images/first_page.png";
import prevBtn from "../../Assets/Images/prev_page.png";
import nextBtn from "../../Assets/Images/next_page.png";
import lastPageBtn from "../../Assets/Images/last_page.png";

const CustomPagination = ({
  totalItemNum,
  itemsCountPerPage,
  pageRangeDisplayed = 5,
  padding,
  setPageNum,
}) => {
  const [activatedPage, setActivatedPage] = useState(1);
  const handleChangePage = (e) => {
    setPageNum(e);
    setActivatedPage(e);
  };

  return (
    <div style={{ padding: padding }}>
      <ReactPagination
        activePage={activatedPage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemNum}
        pageRangeDisplayed={pageRangeDisplayed}
        innerClass={styles.pagination}
        activeClass={styles.activated}
        firstPageText={<img src={firstPageBtn} alt="첫 페이지로 이동" />}
        prevPageText={<img src={prevBtn} alt="이전 페이지로 이동" />}
        nextPageText={<img src={nextBtn} alt="다음 페이지로 이동" />}
        lastPageText={<img src={lastPageBtn} alt="마지막 페이지로 이동" />}
        onChange={handleChangePage}
      />
    </div>
  );
};

export default CustomPagination;
