import { useContext } from "react";
import { MobileContext } from "../../providers/MobileContext";
import styles from "./PartnerList.module.scss";
import CollapsedCategory from "./CollapsedCategory";

const CategoryList = ({ categoryList }) => {
  const isMobile = useContext(MobileContext);

  const checkSubcategories = (category, depth = 1) => {
    if (category && category.length > 0) {
      return category.map((category, index) => {
        return (
          <CollapsedCategory
            depth={depth}
            key={index}
            categoryName={category.name}
          >
            {category.sub && checkSubcategories(category.sub, depth + 1)}
          </CollapsedCategory>
        );
      });
    }
  };

  return (
    <div
      className={styles.category_wrapper}
      style={{
        backgroundColor: isMobile ? "transparent" : "#6385fc",
        width: isMobile ? "100%" : "26rem",
      }}
    >
      {checkSubcategories(categoryList)}
    </div>
  );
};

export default CategoryList;
