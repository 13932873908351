import styles from "./PartnerInfo.module.scss";
import ContactWrapper from "../../../Components/ContactWrapper/ContactWrapper";
import Wrapper from "../../../Components/Layout/Wrapper";
import SwiperSlider from "../../../Components/Swiper/SwiperSlider";
import defaultImage from "../../../Assets/Images/logo01.png";
import locationIcon from "../../../Assets/Images/view_map_clicked.png";

const PartnerInfo = ({ isMobile, partnerData, setPopupOpened }) => {
  return (
    <div className={styles.top_container}>
      <Wrapper>
        <div
          className={styles.info_wrapper}
          style={{ flexDirection: isMobile ? "column" : "row" }}
        >
          <div className={styles.thumbnail_wrapper}>
            {partnerData.thumbnail ? (
              <img src={partnerData.thumbnail} alt="썸네일" />
            ) : (
              <img
                src={defaultImage}
                className={styles.default_img}
                alt="기본 이미지"
              />
            )}
          </div>
          <div className={styles.text_wrapper}>
            <div
              className={styles.title}
              style={{ paddingTop: isMobile ? "4rem" : "0" }}
            >
              {partnerData.company_name}
            </div>
            <div className={styles.location}>
              <img src={locationIcon} alt="병원 위치" />
              <p>{partnerData.addr}</p>
            </div>
            <ContactWrapper
              partnerInfo={partnerData}
              snsGap={1.5}
              iconSize={4}
              setPopupOpened={setPopupOpened}
            />
          </div>
        </div>
        <div className={styles.ad_banner_wrapper}>
          {partnerData.ad_banner &&
            partnerData.ad_banner.item_list.length !== 0 && (
              <SwiperSlider
                swiperList={partnerData.ad_banner.item_list}
                sliderHeight={32}
                borderRadius={2}
              />
            )}
        </div>
      </Wrapper>
    </div>
  );
};

export default PartnerInfo;
