import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MobileContext } from "../../providers/MobileContext";
import styles from "./PartnerList.module.scss";
import arrow from "../../Assets/Images/arrow_down_3.png";

const CollapsedCategory = ({ categoryName, children, depth }) => {
  const isMobile = useContext(MobileContext);
  const [isClicked, setIsClickded] = useState(false);
  const navigate = useNavigate();

  const onToggle = (e) => {
    e.stopPropagation();
    setIsClickded(!isClicked);
  };

  const searchCategory = (e) => {
    e.preventDefault();

    if (children) return;

    const params = {
      category: categoryName,
      keyword: "",
      sort: "popular",
    };

    navigate(`/partners`, {
      state: params,
    });
  };

  return (
    <div
      onClick={onToggle}
      style={{
        backgroundColor: isMobile ? "#6385fc" : "transparent",
        padding: isMobile ? "1rem" : "0",
        borderRadius: "1rem",
      }}
    >
      <div className={styles.category_item}>
        <Link onClick={searchCategory}>
          <p
            className={styles[`category_depth_${depth}`]}
            style={{ paddingLeft: `${depth - 1}rem` }}
          >
            {categoryName}
          </p>
          {children && (
            <img
              src={arrow}
              alt="하위 카테고리 보기"
              style={{
                transform: `rotate(${isClicked ? "180deg" : "0"})`,
              }}
            />
          )}
        </Link>
      </div>
      <div
        style={{
          display: isClicked ? "block" : "none",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsedCategory;
