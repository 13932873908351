import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { MobileContext } from "../../providers/MobileContext";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import i18n from "../../locales/i18n";
import styles from "./Header.module.scss";
import Select from "../Input/Select";
import logo_1 from "../../Assets/Images/logo01.png";
import logo_2 from "../../Assets/Images/logo02.png";
import Wrapper from "../Layout/Wrapper";
import sideMenu_icon from "../../Assets/Images/side_menu.png";
import close_btn from "../../Assets/Images/close_icon.png";
import arrow from "../../Assets/Images/arrow_right.png";

const Header = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const [isSideMenuOpened, setIsSideMenuOpened] = useState(false);
  const langOptList = [
    {
      id: 0,
      value: "KR",
      name: "KR",
    },
    {
      id: 1,
      value: "EN",
      name: "EN",
    },
  ];

  const showCategoryPage = (e, categoryName) => {
    e.preventDefault();

    const params = {
      category: categoryName,
      keyword: "",
      sort: "popular",
    };

    navigate(`/partners`, {
      state: params,
    });
  };

  const showSideMenu = () => {
    setIsSideMenuOpened(true);
    document.documentElement.style.overflow = "hidden";
  };

  const closeSideMenu = () => {
    setIsSideMenuOpened(false);
    document.documentElement.style.overflow = "scroll";
  };
  const changeLanguage = (lang) => i18n.changeLanguage(lang);

  const host = window.location.hostname;
  let logo;

  if (host.includes("k-healthmedia.com")) {
    logo = logo_2;
    document.title = "K-Health MEDIA";
  } else {
    logo = logo_1;
    document.title = "K-Health Hub";
  }

  return (
    <>
      {!isMobile && (
        <div className={styles.top_header}>
          <Wrapper>
            <Link onClick={(e) => showCategoryPage(e, "University Hospital")}>
              University Hospital
            </Link>
            <Link onClick={(e) => showCategoryPage(e, "Plastic Surgery")}>
              Clinic
            </Link>
            <Link onClick={(e) => showCategoryPage(e, "General Hospital")}>
              General Hospital
            </Link>
            <Link onClick={(e) => showCategoryPage(e, "Company")}>Company</Link>
            <Link onClick={(e) => showCategoryPage(e, "Korea Medicine")}>
              Korea Medicine
            </Link>
          </Wrapper>
        </div>
      )}
      <div
        className={styles.header_wrapper}
        style={{ top: isMobile ? "0" : "3.5rem" }}
      >
        <Wrapper>
          <div className={styles.left}>
            <Link to="/">
              <img src={logo} alt="헬스경향 로고 이미지" />
            </Link>
          </div>
          {!isMobile && (
            <div className={styles.center}>
              <Link to="/company">{t(`header.company`)}</Link>
              <Link to="/partners">{t(`header.store_list`)}</Link>
              <Link to="/event">{t(`header.event`)}</Link>
              <Link to="/column">{t(`header.column`)}</Link>
              <Link to="/cardnews">{t(`header.cardnews`)}</Link>
              <Link to="/contact">{t(`header.contact_us`)}</Link>
            </div>
          )}
          <div className={styles.right}>
            {isMobile ? (
              <div className={styles.side_menu_icon} onClick={showSideMenu}>
                <img src={sideMenu_icon} alt="메뉴 보기" />
              </div>
            ) : (
              <div className={styles.language_wrapper}>
                <Select
                  name="language"
                  placeholder="KR"
                  optList={langOptList}
                  onChange={changeLanguage}
                />
              </div>
            )}
          </div>
        </Wrapper>
      </div>
      {isSideMenuOpened && (
        <div
          className={styles.side_menu}
          style={{ top: window.scrollY, left: 0 }}
        >
          <div className={styles.close_btn}>
            <img src={close_btn} alt="메뉴 닫기" onClick={closeSideMenu} />
          </div>
          <div className={styles.nav}>
            <Link to="/company" onClick={closeSideMenu}>
              <p>{t(`header.company`)}</p>
              <img src={arrow} alt="하위 카테고리로 이동" />
            </Link>
            <Link to="/partners" onClick={closeSideMenu}>
              <p>{t(`header.store_list`)}</p>
              <img src={arrow} alt="하위 카테고리로 이동" />
            </Link>
            <Link to="/event" onClick={closeSideMenu}>
              <p>{t(`header.event`)}</p>
              <img src={arrow} alt="하위 카테고리로 이동" />
            </Link>
            <Link to="/column" onClick={closeSideMenu}>
              <p>{t(`header.column`)}</p>
              <img src={arrow} alt="하위 카테고리로 이동" />
            </Link>
            <Link to="/cardnews" onClick={closeSideMenu}>
              <p>{t(`header.cardnews`)}</p>
              <img src={arrow} alt="하위 카테고리로 이동" />
            </Link>
            <Link to="/contact" onClick={closeSideMenu}>
              <p>{t(`header.contact_us`)}</p>
              <img src={arrow} alt="하위 카테고리로 이동" />
            </Link>
          </div>
          <div className={styles.language_wrapper}>
            <Select
              name="language"
              placeholder="KR"
              optList={langOptList}
              onChange={changeLanguage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
