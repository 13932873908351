import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./ArticleContent.module.scss";
import defaultImage from "../../../Assets/Images/logo01.png";
import CustomPagination from "../../../Components/Pagination/CustomPagination";

const ArticleContent = ({ isMobile, partnerInfo }) => {
  const [newArticleList, setNewArticleList] = useState([]);
  const [articlePageNum, setArticlePageNum] = useState(1);

  useEffect(() => {
    if (!partnerInfo) return;

    const itemPerView = isMobile ? 2 : 9;
    const startIndex = (articlePageNum - 1) * itemPerView;
    const endIndex = articlePageNum * itemPerView;

    let newList = [];

    for (
      let i = startIndex;
      i < endIndex && i < partnerInfo.article.item_list.length;
      i++
    ) {
      newList.push(partnerInfo.article.item_list[i]);
    }

    setNewArticleList(newList);
  }, [partnerInfo, articlePageNum, isMobile]);

  return (
    <>
      <div
        className={styles.article_wrapper}
        style={{
          gridTemplateColumns: isMobile ? "unset" : "repeat(3, 1fr)",
        }}
      >
        {newArticleList.map((article, index) => (
          <Link
            to={`/article/articles/${article.id}`}
            className={styles.article}
            key={index}
          >
            <div className={styles.thumbnail_wrapper}>
              {article.thumbnail ? (
                <img src={article.thumbnail} alt="기사 이미지" />
              ) : (
                <img
                  src={defaultImage}
                  className={styles.default_img}
                  alt="기본 이미지"
                />
              )}
            </div>
            <div className={styles.article_title}>{article.title}</div>
            <div className={styles.article_date}>{article.date}</div>
          </Link>
        ))}
      </div>
      <CustomPagination
        totalItemNum={
          partnerInfo.article.total_length > 30
            ? 30
            : partnerInfo.article.total_length
        }
        itemsCountPerPage={isMobile ? 2 : 9}
        padding={"3rem 0 0 0"}
        setPageNum={setArticlePageNum}
      />
    </>
  );
};

export default ArticleContent;
