import { useContext, useEffect, useRef, useState } from "react";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import { MobileContext } from "../../providers/MobileContext";
import styles from "./GoogleMap.module.scss";
import { useNavigate } from "react-router-dom";

const GoogleMap = ({ locations, zoom, mapsize, margin }) => {
  const isMobile = useContext(MobileContext);
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const getLabelContent = (marker) => {
    const location = locations.find(
      (loc) =>
        loc.sns.google_map.lat === marker.getPosition().lat() &&
        loc.sns.google_map.lng === marker.getPosition().lng()
    );

    if (location) {
      const marker = `
        <div onclick="window.location.href='/partner/${location.id}'" 
             class=${styles.label_wrapper}>
          <div class=${styles.label_title}>${location.company_name}</div>
          <div class=${styles.label_description}>${location.addr}</div>
        </div>`;

      return marker;
    }
  };

  useEffect(() => {
    const defaultCenter = {
      lat: 37.5642135,
      lng: 127.0016985,
    };
    const centerItemList = locations.filter(
      (location) => location.sns.google_map !== ""
    );

    const map = new window.google.maps.Map(mapRef.current, {
      center:
        centerItemList.length === 0
          ? defaultCenter
          : centerItemList[0].sns.google_map,
      zoom: zoom,
    });

    map.addListener("click", (e) => {
      setSelectedMarker(null);
    });

    const markersArray = locations.map((location) => {
      const marker = new MarkerWithLabel({
        position: {
          lat: location.sns.google_map.lat,
          lng: location.sns.google_map.lng,
        },
        map: map,
        labelContent: "",
        labelAnchor: isMobile
          ? new window.google.maps.Point(-120, -120)
          : new window.google.maps.Point(-150, -130),
        description: "",
        labelClass: "google_map_label",
      });

      marker.addListener("click", () => {
        setSelectedMarker(marker);
      });

      marker.addListener("dblclick", () => {
        navigate(`/partner/${location.id}`);
      });

      return marker;
    });

    setMarkers(markersArray);
  }, [locations, isMobile, zoom]);

  useEffect(() => {
    markers.forEach((marker) => {
      if (marker === selectedMarker) {
        marker.set("labelContent", getLabelContent(marker));
      } else {
        marker.set("labelContent", "");
      }
    });
  }, [selectedMarker, markers]);

  return (
    <div
      ref={mapRef}
      style={{
        width: "100%",
        height: `${mapsize}rem`,
        margin:
          margin &&
          `${margin[0]}rem ${margin[1]}rem ${margin[2]}rem ${margin[3]}rem`,
      }}
      id="google_map"
    />
  );
};

export default GoogleMap;
