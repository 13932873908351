import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../PartnerList/PartnerList.module.scss";
import map_clicked from "../../Assets/Images/view_map_clicked.png";
import ContactWrapper from "../ContactWrapper/ContactWrapper";
import Popup from "../Popup/Popup";

const ListViewMode = ({ partner }) => {
  const [popupOpened, setPopupOpened] = useState(false);

  return (
    <>
      <div className={styles.list_view_clicked}>
        <Link to={`/partner/${partner.id}`} className={styles.partner_title}>
          {partner.company_name}
        </Link>
        <div className={styles.partner_location}>
          <img src={map_clicked} alt="위치" />
          <p className={styles.location}>{partner.addr}</p>
        </div>
        <div className={styles.description}>{partner.intro}</div>
        {partner.thumbnail && (
          <div className={styles.partner_img}>
            <img src={partner.thumbnail} alt="입점기업 메인 배너" />
          </div>
        )}
        <ContactWrapper
          partnerInfo={partner}
          snsGap={1.5}
          padding={[2, 0]}
          iconSize={4}
          setPopupOpened={setPopupOpened}
        />
      </div>
      {popupOpened && (
        <Popup setPopupOpened={setPopupOpened} partnerID={partner.id} />
      )}
    </>
  );
};

export default ListViewMode;
