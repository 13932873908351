import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { MobileContext } from "../../providers/MobileContext";
import styles from "./PartnerHomePage.module.scss";
import ScrollTopButton from "../../Components/Button/ScrollTopButton";
import Layout from "../../Components/Layout/Layout";
import Search from "../../Components/Search/Search";
import Wrapper from "../../Components/Layout/Wrapper";
import Popup from "../../Components/Popup/Popup";
import BasicAPI from "../../API/BasicAPI";
import Gallery from "../../Components/Gallery/Gallery";
import Navigation from "../../Components/Navigation/Navigation";
import PartnerInfo from "./PartnerInfo/PartnerInfo";
import Intro from "./Intro/Intro";
import Content from "./Content/Content";
import YoutubeContent from "./Youtube/YoutubeContent";
import ArticleContent from "./Article/ArticleContent";
import GalleryContent from "./Gallery/Gallery";

const PartnerHomePage = () => {
  const { id } = useParams();
  const sectionRef = useRef({});
  const isMobile = useContext(MobileContext);

  const [partnerInfo, setPartnerInfo] = useState("");
  const [popupOpened, setPopupOpened] = useState(false);
  const [galleryOpened, setGalleryOpened] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const [navList, setNavList] = useState([]);

  const scrollToSection = (sectionId) => {
    const section = sectionRef.current[sectionId];

    if (section) {
      const headerOffset = 80;
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      section.scrollIntoView();

      window.scrollTo({
        top: offsetPosition,
      });
    }
  };

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/partner/partners/${id}`,
      success: (res) => setPartnerInfo(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  useEffect(() => {
    partnerInfo &&
      setNavList({
        intro: !!partnerInfo.intro,
        about: !!partnerInfo.description,
        youtube: !!partnerInfo.youtube.user_id,
        article:
          partnerInfo.article && partnerInfo.article.item_list.length > 0,
        gallery:
          partnerInfo.gallery && partnerInfo.gallery.item_list.length > 0,
      });
  }, [partnerInfo]);

  return (
    <Layout>
      <Search />
      {partnerInfo && (
        <div className={styles.content_wrapper}>
          <PartnerInfo
            isMobile={isMobile}
            partnerData={partnerInfo}
            setPopupOpened={setPopupOpened}
          />
          <Navigation
            navList={navList}
            scrollToSection={scrollToSection}
            isMobile={isMobile}
          />
          <div className={styles.main_content_wrapper}>
            <Wrapper>
              {partnerInfo.intro && partnerInfo.sns && (
                <Intro partnerInfo={partnerInfo} sectionRef={sectionRef} />
              )}
              {partnerInfo.description && (
                <Content sectionRef={sectionRef} partnerInfo={partnerInfo} />
              )}
              {partnerInfo.youtube.user_id !== "" && (
                <YoutubeContent
                  sectionRef={sectionRef}
                  partnerInfo={partnerInfo}
                />
              )}
              {partnerInfo.article.item_list.length !== 0 && (
                <div
                  className={styles.content}
                  ref={(el) => (sectionRef.current["article"] = el)}
                >
                  <div className={styles.title}>Article</div>
                  <ArticleContent
                    sectionRef={sectionRef}
                    isMobile={isMobile}
                    partnerInfo={partnerInfo}
                  />
                </div>
              )}
              {partnerInfo.gallery.item_list.length !== 0 && (
                <div
                  className={styles.content}
                  ref={(el) => (sectionRef.current["gallery"] = el)}
                >
                  <div className={styles.title}>Gallery</div>
                  <GalleryContent
                    isMobile={isMobile}
                    partnerInfo={partnerInfo}
                    setGalleryOpened={setGalleryOpened}
                    setSelectedImg={setSelectedImg}
                  />
                </div>
              )}
            </Wrapper>
          </div>
        </div>
      )}
      {popupOpened && <Popup setPopupOpened={setPopupOpened} partnerID={id} />}
      {galleryOpened && (
        <Gallery
          imgList={partnerInfo.gallery.item_list}
          setGalleryOpened={setGalleryOpened}
          startIndex={selectedImg}
        />
      )}
      <ScrollTopButton />
    </Layout>
  );
};

export default PartnerHomePage;
