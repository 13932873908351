import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import i18n from "../../locales/i18n";
import styles from "./Search.module.scss";
import Input from "../Input/Input";
import searchIcon from "../../Assets/Images/search.png";
import Wrapper from "../Layout/Wrapper";

const Search = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");

  const handleChangeSearchData = (e) => setKeyword(e.target.value);
  const searchKeyword = () => {
    if (keyword === "") {
      alert(t(`search`));
      return;
    }

    const params = {
      category: "",
      keyword: keyword,
      sort: "popular",
    };

    navigate(`/partners`, {
      state: params,
    });
  };

  return (
    <div className={styles.search_wrapper}>
      <Wrapper>
        <Input
          placeholder={t(`search`)}
          rightIcon={
            <img
              src={searchIcon}
              alt="키워드 검색"
              style={{ width: "2.4rem" }}
              onClick={searchKeyword}
            />
          }
          onChange={handleChangeSearchData}
          onKeyDown={(e) => e.key === "Enter" && searchKeyword()}
          name="keyword"
        />
      </Wrapper>
    </div>
  );
};

export default Search;
