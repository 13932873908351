import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../Components/Layout/Layout";
import Wrapper from "../../Components/Layout/Wrapper";
import CategoryTitle from "../../Components/Title/CategoryTitle";
import CategorySearch from "../../Components/Search/CategorySearch";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import Gallery from "./Gallery";
import BasicAPI from "../../API/BasicAPI";

const CardNewsPage = () => {
  const { t } = useTranslation();
  const [cardnewsList, setCardnewsList] = useState([]);
  const searchFilterList = [{ value: "title", text: "제목" }];

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/board/?category=cardnews`,
      success: (res) => setCardnewsList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <Layout>
      <Wrapper>
        <CategoryTitle
          title={t(`cardnews.cardnews`)}
          description={t(`cardnews.description`)}
          padding={`3rem 0 0 0`}
        />
        <CategorySearch
          commonCategory={`board`}
          category={`cardnews`}
          filterList={searchFilterList}
          setContentList={setCardnewsList}
        />
        <Gallery category="cardnews" itemList={cardnewsList} />
        <CustomPagination
          totalItemNum={5}
          itemsCountPerPage={5}
          pageRangeDisplayed={10}
          padding="1rem"
        />
      </Wrapper>
    </Layout>
  );
};

export default CardNewsPage;
