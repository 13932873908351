import { useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import styles from "./SwiperSlider.module.scss";
import arrow from "../../Assets/Images/arrow_left.png";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const SwiperSlider = ({
  categoryName,
  swiperList,
  sliderHeight,
  borderRadius,
  hidePagination = true,
  hideNavigation = false,
  delay = 3000,
  margin,
}) => {
  const swiperRef = useRef(null);

  const handlePrev = () => swiperRef.current.swiper.slidePrev();
  const handleNext = () => swiperRef.current.swiper.slideNext();

  return (
    <>
      {swiperList.length !== 0 && (
        <Swiper
          ref={swiperRef}
          className={styles.banner}
          modules={[Autoplay, Pagination, Navigation]}
          slidesPerView={1}
          loop
          autoplay={{ delay: delay }}
          pagination={hidePagination}
          navigation={
            hideNavigation && {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }
          }
          style={{
            height: sliderHeight && `${sliderHeight}rem`,
            margin: margin,
          }}
        >
          {swiperList.map((item) => (
            <SwiperSlide key={item.id}>
              <Link
                to={
                  item.link ||
                  (categoryName ? `/${categoryName}/${item.id}` : "#")
                }
              >
                <img
                  src={item.image || item.thumbnail || item.file}
                  alt="슬라이드 이미지"
                  style={{
                    borderRadius: borderRadius ? "2rem" : 0,
                  }}
                />
              </Link>
            </SwiperSlide>
          ))}
          {hideNavigation && (
            <>
              <div className={styles.swiperButtonPrev} onClick={handlePrev}>
                <img src={arrow} alt="이전 보기" className={styles.prev_btn} />
              </div>
              <div className={styles.swiperButtonNext} onClick={handleNext}>
                <img src={arrow} alt="다음 보기" className={styles.next_btn} />
              </div>
            </>
          )}
        </Swiper>
      )}
    </>
  );
};

export default SwiperSlider;
