import { useContext } from "react";
import { MobileContext } from "../../providers/MobileContext";
import UserProfile from "./UserProfile/UserProfile";
import MainVideo from "./MainVideo/MainVideo";
import VideoList from "./VideoList/VideoList";

const Youtube = ({ channelInfo, mainVideo, videoList }) => {
  const isMobile = useContext(MobileContext);

  return (
    <div>
      <UserProfile isMobile={isMobile} channelInfo={channelInfo} />
      <MainVideo mainVideo={mainVideo} />
      <VideoList isMobile={isMobile} videoList={videoList} />
    </div>
  );
};

export default Youtube;
