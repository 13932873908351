import styles from "../PartnerHomePage.module.scss";

const Content = ({ sectionRef, partnerInfo }) => {
  return (
    <div
      className={styles.content}
      ref={(el) => (sectionRef.current["about"] = el)}
    >
      <div className={styles.title}>About</div>
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: partnerInfo.description,
        }}
      ></div>
    </div>
  );
};

export default Content;
