import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MobileContext } from "../../../providers/MobileContext";
import Wrapper from "../../../Components/Layout/Wrapper";
import styles from "./Content.module.scss";
import EventContent from "./EventContent/EventContent";
import ColumnContent from "./ColumnContent/ColumnContent";
import CardNewsContent from "./CardNewsContent/CardNewsContent";
import AdBannerContent from "./AdBannerContent/AdBannerContent";

const Content = () => {
  const navigate = useNavigate();
  const isMobile = useContext(MobileContext);

  const movePage = (e, categoryName) => {
    e.preventDefault();
    navigate(`/${categoryName}`);
  };

  return (
    <div className={styles.content}>
      <Wrapper>
        <div
          className={styles.content_wrapper}
          style={{ flexDirection: isMobile ? "column" : "row" }}
        >
          <EventContent isMobile={isMobile} movePage={movePage} />
          <ColumnContent isMobile={isMobile} movePage={movePage} />
          <CardNewsContent isMobile={isMobile} movePage={movePage} />
        </div>
        <AdBannerContent />
      </Wrapper>
    </div>
  );
};

export default Content;
