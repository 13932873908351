import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckEmail } from "../../Validation/Validations";
import { MobileContext } from "../../providers/MobileContext";
import { CheckWordLength } from "../../Validation/Validations";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import i18n from "../../locales/i18n";
import Layout from "../../Components/Layout/Layout";
import Label from "../../Components/Input/Label";
import Input from "../../Components/Input/Input";
import Textarea from "../../Components/Input/Textarea";
import BasicAPI from "../../API/BasicAPI";
import Wrapper from "../../Components/Layout/Wrapper";
import Form from "../../Components/Form/Form";

const ContactPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useContext(MobileContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    title: "",
    message: "",
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitForm = () => {
    if (formData.name === "") {
      alert(t(`popup.name_placeholder`));
      return;
    }
    if (formData.email === "") {
      alert(t(`popup.email_placeholder`));
      return;
    }
    if (formData.title === "") {
      alert(t(`popup.inquiry_subject_plcaeholder`));
      return;
    }
    if (formData.message === "") {
      alert(t(`popup.inquiry_placeholder`));
      return;
    }
    if (!CheckEmail(formData.email)) {
      alert(t(`popup.invalid_email`));
      return;
    }

    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/partner/contact/`,
      method: "POST",
      data: formData,
      success: () => {
        alert(t(`partners.submit_success`));
        navigate("/");
      },
      fail: (res) => console.error(res),
    });
  };

  return (
    <Layout>
      <div
        style={{
          backgroundColor: isMobile ? "white" : "#f7f8fb",
        }}
      >
        <Wrapper>
          <Form
            title={t(`header.contact_us`)}
            btnText={t(`popup.submit`)}
            onClick={submitForm}
          >
            <div>
              <Label label={t(`popup.name`)} />
              <Input
                name="name"
                value={formData.name}
                onChange={onChangeInput}
                placeholder={t(`popup.name_placeholder`)}
              />
            </div>
            <div>
              <Label label={t(`popup.email`)} />
              <Input
                name="email"
                value={formData.email}
                onChange={onChangeInput}
                placeholder={t(`popup.email_placeholder`)}
              />
            </div>
            <div>
              <Label label={t(`popup.inquiry`)} />
              <Input
                name="title"
                value={formData.title}
                onChange={onChangeInput}
                placeholder={t(`popup.inquiry_subject`)}
              />
              <Textarea
                name="message"
                value={formData.message}
                onChange={onChangeInput}
                rows="10"
                placeholder={t(`popup.inquiry`)}
              />
            </div>
          </Form>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default ContactPage;
