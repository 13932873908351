import { useEffect, useState } from "react";
import styles from "./Gallery.module.scss";
import CustomPagination from "../../../Components/Pagination/CustomPagination";

const GalleryContent = ({
  isMobile,
  partnerInfo,
  setGalleryOpened,
  setSelectedImg,
}) => {
  const [newGalleryList, setNewGalleryList] = useState([]);
  const [galleryPageNum, setGalleryPageNum] = useState(1);

  const openGalleryPopup = (index) => {
    let tempNum;

    isMobile
      ? (tempNum = 2 * galleryPageNum - 2)
      : (tempNum = 12 * (galleryPageNum - 1));

    galleryPageNum < 2
      ? setSelectedImg(index)
      : setSelectedImg(tempNum + index);

    setGalleryOpened(true);
    document.documentElement.style.overflow = "hidden";
  };

  useEffect(() => {
    if (!partnerInfo) return;

    const itemPerView = isMobile ? 2 : 12;
    const startIndex = (galleryPageNum - 1) * itemPerView;
    const endIndex = galleryPageNum * itemPerView;

    let newList = [];

    for (
      let i = startIndex;
      i < endIndex && i < partnerInfo.gallery.item_list.length;
      i++
    ) {
      newList.push(partnerInfo.gallery.item_list[i]);
    }

    setNewGalleryList(newList);
  }, [partnerInfo, galleryPageNum, isMobile]);

  return (
    <>
      <div
        className={styles.gallery_wrapper}
        style={{
          gridTemplateColumns: isMobile ? "unset" : "repeat(3, 1fr)",
        }}
      >
        {newGalleryList.map((img, index) => (
          <div
            className={styles.img_wrapper}
            onClick={() => openGalleryPopup(index)}
            key={img.id}
          >
            <img src={img.original} alt="갤러리 이미지" id={img.id} />
          </div>
        ))}
      </div>
      <CustomPagination
        totalItemNum={
          partnerInfo.gallery.total_length > 30
            ? 30
            : partnerInfo.gallery.total_length
        }
        itemsCountPerPage={isMobile ? 2 : 12}
        padding={"3rem 0 0 0"}
        setPageNum={setGalleryPageNum}
      />
    </>
  );
};

export default GalleryContent;
