import { useEffect, useState } from "react";
import styles from "../PopularNews.module.scss";
import BasicAPI from "../../../../API/BasicAPI";
import SwiperSlider from "../../../../Components/Swiper/SwiperSlider";
import MainVideo from "../../../../Components/Youtube/MainVideo/MainVideo";

const YoutubeBanner = () => {
  const [videoList, setVideoList] = useState([]);
  const [thumbnailList, setThumbnailList] = useState([]);

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/partner/partners/youtube/`,
      success: (res) => setVideoList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  /*   useEffect(() => {
    const fetchThumbnails = async () => {
      const newThumbnailList = [];

      for (const item of videoList) {
        try {
          const response = await BasicAPI({
            url: "https://www.googleapis.com/youtube/v3/videos",
            method: "GET",
            data: {
              id: item.youtube_id,
              key: process.env.REACT_APP_GOOGLE_KEY,
              part: "snippet",
            },
          });

          if (response.items.length > 0) {
            const thumbnailUrl = response.items[0].snippet.thumbnails.high.url;
            newThumbnailList.push(thumbnailUrl);
          }
        } catch (error) {
          console.error(error);
        }
      }

      setThumbnailList(newThumbnailList);
    };

    if (videoList.length > 0) {
      fetchThumbnails();
    }
  }, [videoList]); */

  /*   useEffect(() => {
    if (videoList.length > 0) {
      videoList.map((item) =>
        BasicAPI({
          url: "https://www.googleapis.com/youtube/v3/videos",
          method: "GET",
          data: {
            id: item.youtube_id,
            key: process.env.REACT_APP_GOOGLE_KEY,
            part: "snippet",
          },
          success: (res) => {
            const thumbnailUrl = res.items[0].snippet.thumbnails.high.url;
            newThumbnailList.push(thumbnailUrl);
            setThumbnailList(newThumbnailList);
          },
          fail: (res) => console.error(res),
        })
      );
    }
  }, [videoList]); */

  /*   useEffect(() => {
    console.log("thumbnailList");
    console.log(thumbnailList);
  }, [thumbnailList]); */

  /* 썸네일이 포함된 하나의 통합된 배열 만들기 */

  /* 
    mainvideo.id - 영상 링크용, 
    mainVideo.snippet.thumbnails.medium.url - 썸네일용
  */
  /* 
  useEffect(() => {
    if (videoList.length > 0)
      BasicAPI({
        url: "https://www.googleapis.com/youtube/v3/videos",
        method: "GET",
        data: {
          id: partnerInfo.youtube.main_video_url,
          key: process.env.REACT_APP_GOOGLE_KEY,
          part: "snippet",
        },
        success: (res) => setYoutubeMainVideo(res.items[0]),
        fail: (res) => console.error(res),
      });
  }, [videoList]); */
  /* 
  
  
  
  
  
  */

  /*   useEffect(() => {
    if(videoList.length > 0){
 BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/partner/partners/youtube/`,
      success: (res) => {
        console.log(res.data);
        setVideoList(res.data);
      },
      fail: (res) => console.error(res),
    });
    }
    
  }, [videoList]); */

  /* 데이터가 들어오면 썸네일 받아오기 */

  return (
    <div className={styles.youtube_banner_list}>
      {/* <SwiperSlider
        swiperList={thumbnailList}
        hidePagination={false}
        delay={4000}
      />
          <MainVideo /> */}
    </div>
  );
};

export default YoutubeBanner;
