import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MobileContext } from "../../providers/MobileContext";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import i18n from "../../locales/i18n";
import styles from "./PartnersPage.module.scss";
import Layout from "../../Components/Layout/Layout";
import Search from "../../Components/Search/Search";
import CategoryList from "../../Components/PartnerList/CategoryList";
import HospitalList from "../../Components/PartnerList/HospitalList";
import Wrapper from "../../Components/Layout/Wrapper";
import ScrollTopButton from "../../Components/Button/ScrollTopButton";
import BasicAPI from "../../API/BasicAPI";
import CategoryTitle from "../../Components/Title/CategoryTitle";

const PartnersPage = () => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const location = useLocation();
  const [categoryList, setCategoryList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const { keyword, category, sort } = location.state || {
    keyword: "",
    category: "",
    sort: "",
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_SERVICE_URL}/partner/partners?name=${keyword}&category=${category}&sort=${sort}`;

    BasicAPI({
      url: url,
      method: "GET",
      success: (res) => setPartnerList(res.data),
      fail: (error) => console.error(error),
    });

    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/partner/categories/`,
      method: "GET",
      success: (res) => setCategoryList(res.data),
      fail: (error) => console.error(error),
    });
  }, [keyword, category, sort]);

  return (
    <Layout>
      <Search />
      <Wrapper>
        <CategoryTitle
          title={t(`partners.partner_list`)}
          description={t(`partners.description`)}
        />
        <div
          className={styles.flex_wrapper}
          style={{ flexDirection: isMobile ? "column" : "row" }}
        >
          <CategoryList categoryList={categoryList} />
          <HospitalList partnerList={partnerList} keyword={keyword} />
        </div>
      </Wrapper>
      <ScrollTopButton />
    </Layout>
  );
};

export default PartnersPage;
