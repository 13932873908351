import styles from "./Input.module.scss";

const Input = ({
  disabled,
  type,
  name,
  value,
  maxLength,
  placeholder,
  onChange,
  rightIcon,
  toggleState,
  onKeyDown,
}) => {
  const handleMaxLengthError = (event) => {
    const { value } = event.target;
    if (value.length > maxLength)
      event.target.value = value.slice(0, maxLength);
  };

  return (
    <div className={styles.input_wrapper}>
      <input
        disabled={disabled}
        type={type !== "password" ? type : toggleState ? "password" : "text"}
        name={name}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onInput={handleMaxLengthError}
      />
      {rightIcon && (
        <div className={styles.right_icon_wrapper}>{rightIcon}</div>
      )}
    </div>
  );
};

export default Input;
