import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../Content.module.scss";
import SwiperSlider from "../../../../Components/Swiper/SwiperSlider";
import BasicAPI from "../../../../API/BasicAPI";

const EventContent = ({ isMobile, movePage }) => {
  const { t } = useTranslation();
  const [cardNewsList, setCardNewsList] = useState([]);

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/board/?category=cardnews`,
      success: (res) => setCardNewsList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <>
      {cardNewsList.length !== 0 && (
        <div
          className={styles.card_news}
          style={{ width: isMobile ? "100%" : "46rem" }}
        >
          <div className={styles.title_wrapper}>
            <div className={styles.title}>{t`header.cardnews`}</div>
            <Link
              className={styles.more_btn}
              onClick={(e) => movePage(e, "cardnews")}
            >
              {t`main.more`}
            </Link>
          </div>
          <div className={styles.board_wrapper}>
            <SwiperSlider
              categoryName="cardnews"
              swiperList={cardNewsList}
              sliderHeight={35}
              hidePagination={false}
              hideNavigation={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EventContent;
