import styles from "./CategoryTitle.module.scss";

const CategoryTitle = ({ title, description, padding = "3rem 0" }) => {
  return (
    <div className={styles.title_wrapper} style={{ padding: padding }}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default CategoryTitle;
