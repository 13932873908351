import styles from "./MainVideo.module.scss";

const MainVideo = ({ mainVideo }) => {
  return (
    mainVideo !== "" && (
      <div className={styles.main_video_wrapper}>
        <a
          href={`https://www.youtube.com/watch?v=${mainVideo.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={mainVideo.snippet.thumbnails.medium.url}
            alt="메인 영상 보기"
          />
        </a>
      </div>
    )
  );
};

export default MainVideo;
