import { useEffect, useState } from "react";
import styles from "./Service.module.scss";
import Layout from "../../Components/Layout/Layout";
import BasicAPI from "../../API/BasicAPI";

const ServicePage = () => {
  const [serviceTerm, setServiceTerm] = useState("");

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/config/service`,
      success: (res) => setServiceTerm(res),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <Layout>
      <div className={styles.service_term}>{serviceTerm}</div>
    </Layout>
  );
};

export default ServicePage;
