import styles from "./Label.module.scss";

const Label = ({ label, warning }) => {
  return (
    <div className={styles.label_wrapper}>
      <label>{label}</label>
      {warning && <p>{warning}</p>}
    </div>
  );
};

export default Label;
