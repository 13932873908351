import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import styles from "./NewsSwiper.module.scss";
import "swiper/css";
import "swiper/css/pagination";
import default_thumbnail from "../../Assets/Images/logo01.png";

const NewsSwiper = ({ swiperList }) => {
  return (
    <Swiper
      className={styles.wrapper}
      modules={[Autoplay, Pagination]}
      slidesPerView={1}
      loop
      autoplay={{ delay: 5000 }}
    >
      {swiperList.map((item) => (
        <SwiperSlide key={item.id}>
          <Link to={`/article/articles/${item.id}`}>
            <div className={styles.thumbnail}>
              <img
                src={item.thumbnail ? item.file : default_thumbnail}
                alt="기사 썸네일"
              />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.text}>{item.content_text}</div>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default NewsSwiper;
