import styles from "../Content.module.scss";
import AdBannerComponent from "../../../../Components/AdBanner/AdBannerComponent";

const AdBannerContent = () => {
  return (
    <div className={styles.ad_banner_wrapper}>
      <AdBannerComponent sliderHeight={`5`} targetCode={`AD02`} />
      <AdBannerComponent sliderHeight={`5`} targetCode={`AD03`} />
      <AdBannerComponent sliderHeight={`5`} targetCode={`AD04`} />
      <AdBannerComponent sliderHeight={`5`} targetCode={`AD05`} />
      <AdBannerComponent sliderHeight={`5`} targetCode={`AD06`} />
    </div>
  );
};

export default AdBannerContent;
