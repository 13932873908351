import styles from "./Button.module.scss";

const Button = ({ type, text, onClick }) => {
  return (
    <>
      <input
        type="button"
        className={styles[type]}
        value={text}
        onClick={onClick}
      ></input>
    </>
  );
};

export default Button;
