import { Link } from "react-router-dom";
import styles from "./SNSWrapper.module.scss";
import sns_kakao from "../../Assets/Images/kakao_icon.png";
import sns_wechat from "../../Assets/Images/wechat_icon.png";
import sns_tel from "../../Assets/Images/tel_icon.png";
import sns_googlemap from "../../Assets/Images/googlemap_icon.png";
import sns_instagram from "../../Assets/Images/instagram_icon.png";
import sns_facebook from "../../Assets/Images/facebook_icon.png";
import sns_youtube from "../../Assets/Images/youtube_icon.png";

const SNSWrapper = ({ snsList, iconSize, gap }) => {
  const snsImgList = {
    kakao: {
      src: sns_kakao,
      alt: "카카오 채팅으로 이동",
      url: `https://pf.kakao.com/${snsList.kakao && snsList.kakao}/chat`,
    },
    wechat: {
      src: sns_wechat,
      alt: "위챗 채팅으로 이동",
      url: `weixin://dl/chat?${snsList.wechat && snsList.wechat}`,
    },
    tel: {
      src: sns_tel,
      alt: "전화 연결",
      url: `tel:${snsList.tel && snsList.tel}`,
    },
    google_map: {
      src: sns_googlemap,
      alt: "구글맵으로 이동",
      url: `https://www.google.co.kr/maps/place/${
        snsList.google_map && snsList.google_map.lat
      },${snsList.google_map && snsList.google_map.lng}`,
    },
    instagram: {
      src: sns_instagram,
      alt: "인스타그램으로 이동",
      url: `https://www.instagram.com/${
        snsList.instagram && snsList.instagram
      }`,
    },
    facebook: {
      src: sns_facebook,
      alt: "페이스북으로 이동",
      url: `https://facebook.com/${snsList.facebook && snsList.facebook}`,
    },
    youtube: {
      src: sns_youtube,
      alt: "유튜브로 이동",
      url: `https://www.youtube.com/channel/${
        snsList.youtube && snsList.youtube
      }`,
    },
  };

  return (
    <div className={styles.sns} style={{ columnGap: `${gap}rem` }}>
      {snsList &&
        Object.keys(snsList)
          .filter((sns) => snsList[sns] && sns !== "website" && sns !== "email")
          .map((sns, index) => (
            <Link key={index} target="_blank" to={snsImgList[sns].url}>
              <img
                src={snsImgList[sns].src}
                style={{ width: `${iconSize}rem` }}
                alt={snsImgList[sns].alt}
              />
            </Link>
          ))}
    </div>
  );
};
export default SNSWrapper;
