import { useContext } from "react";
import { MobileContext } from "../../../providers/MobileContext";
import Wrapper from "../../../Components/Layout/Wrapper";
import styles from "./PopularNews.module.scss";
import LatestNews from "./LatestNews/LatestNews";
import PopularArticle from "./PopularArticle/PopularArticle";
import YoutubeBanner from "./YoutubeBanner/YoutubeBanner";
import AdBannerComponent from "../../../Components/AdBanner/AdBannerComponent";

const PopularNews = () => {
  const isMobile = useContext(MobileContext);

  return (
    <div
      className={styles.popular_news}
      style={{ height: isMobile ? "unset" : "60rem" }}
    >
      <Wrapper>
        <div
          className={styles.column_wrapper}
          style={{ flexDirection: isMobile ? "column" : "row" }}
        >
          <LatestNews />
          <PopularArticle />
          <div
            className={styles.banner_wrapper}
            style={{ width: isMobile ? "100%" : "45rem" }}
          >
            <AdBannerComponent
              targetCode={`AD01`}
              sliderHeight={`23`}
              borderRadius={2}
            />
            <YoutubeBanner />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default PopularNews;
