import { useContext } from "react";
import { MobileContext } from "../../providers/MobileContext";
import styles from "./ContactWrapper.module.scss";
import SNSWrapper from "./SNSWrapper";
import ButtonWrapper from "./ButtonWrapper";

const ContactWrapper = ({
  partnerInfo,
  snsGap,
  padding,
  iconSize,
  setPopupOpened,
  setStartPos,
}) => {
  const isMobile = useContext(MobileContext);

  return (
    <div
      className={styles.contact_wrapper}
      style={{
        padding: padding && `${padding[0]}rem ${padding[1]}rem`,
        boxSizing: "border-box",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <SNSWrapper snsList={partnerInfo.sns} gap={snsGap} iconSize={iconSize} />
      {setPopupOpened && (
        <ButtonWrapper
          contactList={partnerInfo.sns}
          setPopupOpened={setPopupOpened}
          setStartPos={setStartPos}
        />
      )}
    </div>
  );
};

export default ContactWrapper;
