import { Link } from "react-router-dom";
import { useContext } from "react";
import { MobileContext } from "../../providers/MobileContext";
import styles from "./ColumnList.module.scss";

const ColumnList = ({ columnList }) => {
  const isMobile = useContext(MobileContext);

  return (
    <div className={styles.wrapper}>
      {columnList.map((item) => (
        <div
          className={styles.column_item}
          key={item.id}
          style={{
            flexDirection: isMobile ? "column" : "row",
            height: isMobile ? "auto" : "20rem",
          }}
        >
          <Link
            to={`/article/columns/${item.id}`}
            className={styles.thumbnail}
            style={{
              width: isMobile ? "100%" : "15%",
              height: isMobile ? "25rem" : "100%",
            }}
          >
            <img src={item.column_writer.thumbnail} alt="칼럼 썸네일" />
          </Link>
          <div
            className={styles.text_wrapper}
            style={{ width: isMobile ? "100%" : "85%" }}
          >
            <div className={styles.series_name}>{item.column_series.title}</div>
            <Link
              to={`/article/columns/${item.id}`}
              className={styles.title_wrapper}
            >
              <div className={styles.title}>{item.title}</div>
              <div className={styles.date}>{item.updated_at.slice(2, 10)}</div>
              <div className={styles.content}>{item.cleaned_content}</div>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ColumnList;
