import { Link } from "react-router-dom";
import { useContext } from "react";
import { MobileContext } from "../../providers/MobileContext";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import i18n from "../../locales/i18n";
import styles from "./Footer.module.scss";
import logo_1 from "../../Assets/Images/logo01.png";
import logo_2 from "../../Assets/Images/logo02.png";
import footer_icon_1 from "../../Assets/Images/footer_icon_1.png";
import footer_icon_2 from "../../Assets/Images/footer_icon_2.png";
import footer_icon_3 from "../../Assets/Images/footer_icon_3.png";
import Wrapper from "../Layout/Wrapper";

const Footer = () => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const host = window.location.hostname;
  let logo;

  if (host.includes("k-healthmedia.com")) {
    logo = logo_2;
  } else {
    logo = logo_1;
  }

  return (
    <div className={styles.footer_wrapper}>
      <Wrapper>
        <div className={styles.footer_logo_wrapper}>
          <img src={logo} alt="헬스경향 로고 이미지" />
        </div>
        <div
          className={styles.f_top}
          style={{ flexDirection: isMobile ? "column" : "row" }}
        >
          <div className={styles.f_top_left}>
            <div className={styles.text_wrapper}>
              {isMobile ? (
                <>
                  <div className={styles.text_row}>
                    <div className={styles.text_item}>
                      {t(`footer.corporation_name`)}
                    </div>
                    <div className={styles.text_item}>
                      {t(`footer.address`)}
                    </div>
                  </div>
                  <div className={styles.text_row}>
                    <div className={styles.text_item}>
                      {t(`footer.youth_protection_director`)}
                    </div>
                    <div className={styles.text_item}>
                      {t(`footer.registration_number`)}
                    </div>
                  </div>
                  <div className={styles.text_row}>
                    <div className={styles.text_item}>
                      {t(`footer.registragion_date`)}
                    </div>
                    <div className={styles.text_item}>
                      {t(`footer.issue_date`)}
                    </div>
                    <div className={styles.text_item}>
                      {t(`footer.publishier`)}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.text_row}>
                    <div className={styles.text_item}>
                      {t(`footer.corporation_name`)}
                    </div>
                    <div className={styles.text_item}>
                      {t(`footer.address`)}
                    </div>
                    <div className={styles.text_item}>
                      {t(`footer.youth_protection_director`)}
                    </div>
                  </div>
                  <div className={styles.text_row}>
                    <div className={styles.text_item}>
                      {t(`footer.registration_number`)}
                    </div>
                    <div className={styles.text_item}>
                      {" "}
                      {t(`footer.registragion_date`)}
                    </div>
                    <div className={styles.text_item}>
                      {" "}
                      {t(`footer.issue_date`)}
                    </div>
                    <div className={styles.text_item}>
                      {" "}
                      {t(`footer.publishier`)}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className={styles.bottom_text}
              style={{ paddingTop: isMobile ? "2rem" : "0" }}
            >
              {t(`footer.ethic_description`)}
            </div>
          </div>
          <div
            className={styles.f_top_right}
            style={{ paddingTop: isMobile ? "2rem" : "0" }}
          >
            <a
              href="mailto:desk@k-health.com"
              className={styles.icon_wrapper}
              style={{ justifyContent: isMobile ? "left" : "right" }}
            >
              <img src={footer_icon_1} alt="메일주소 아이콘" />
              <p>desk@k-health.com</p>
            </a>
            <a href="tel:02-3701-1582" className={styles.icon_wrapper}>
              <img src={footer_icon_2} alt="전화 아이콘" />
              <p>02-3701-1582</p>
            </a>
            <a className={styles.icon_wrapper}>
              <img src={footer_icon_3} alt="팩스 아이콘" />
              <p>02-6272-1580</p>
            </a>
          </div>
        </div>
        <div
          className={styles.f_bottom}
          style={{
            gridTemplateColumns: isMobile ? "unset" : "repeat(2, 1fr)",
          }}
        >
          <div
            className={styles.f_bottom_left}
            style={{ order: isMobile ? "1" : "0" }}
          >
            Copyright ⓒ 2024 {t(`corporation_name`)}. All Rights Reserved.
          </div>
          <div
            className={styles.f_bottom_right}
            style={{
              order: isMobile ? "0" : "1",
              textAlign: isMobile ? "left" : "right",
            }}
          >
            <Link to="/service"> {t(`footer.terms_of_use`)}</Link>
            <Link to="/policy">{t(`footer.privacy`)}</Link>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default Footer;
