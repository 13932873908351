import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../Components/Layout/Layout";
import Wrapper from "../../Components/Layout/Wrapper";
import CategoryTitle from "../../Components/Title/CategoryTitle";
import CategorySearch from "../../Components/Search/CategorySearch";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import ColumnList from "./ColumnList";
import BasicAPI from "../../API/BasicAPI";

const ColumnPage = () => {
  const { t } = useTranslation();
  const [columnList, setColumnList] = useState([]);
  const searchFilterList = [
    { value: "title", text: "제목" },
    { value: "content", text: "내용" },
    { value: "series", text: "시리즈명" },
    { value: "writer", text: "작성자명" },
  ];

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/article/columns/`,
      success: (res) => setColumnList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <Layout>
      <Wrapper>
        <CategoryTitle
          title={t(`column.column`)}
          description={t(`column.description`)}
          padding={`3rem 0 0 0`}
        />
        <CategorySearch
          commonCategory={`article/columns`}
          filterList={searchFilterList}
          setContentList={setColumnList}
        />
        <ColumnList columnList={columnList} setColumnList={setColumnList} />
        <CustomPagination
          totalItemNum={5}
          itemsCountPerPage={5}
          pageRangeDisplayed={10}
          padding="1rem"
        />
      </Wrapper>
    </Layout>
  );
};

export default ColumnPage;
