import { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MobileContext } from "../../providers/MobileContext";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import i18n from "../../locales/i18n";
import styles from "./PartnerList.module.scss";
import Select from "../Input/Select";
import ListViewMode from "../ViewMode/ListViewMode";
import GridViewMode from "../ViewMode/GridViewMode";
import GoogleMap from "../GoogleMap/GoogleMap";

/* img */
import list from "../../Assets/Images/view_list.png";
import grid from "../../Assets/Images/view_grid.png";
import map from "../../Assets/Images/view_map.png";
import list_clicked from "../../Assets/Images/view_list_clicked.png";
import grid_clicked from "../../Assets/Images/view_grid_clicked.png";
import map_clicked from "../../Assets/Images/view_map_clicked.png";

const HospitalList = ({ partnerList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useContext(MobileContext);
  const [viewState, setViewState] = useState("list_view_clicked");
  const filterList = [
    {
      id: 0,
      value: "distance",
      name: t(`partners.distance`),
    },
    { id: 1, value: "popular", name: t(`partners.popular`) },
    { id: 2, value: "new", name: t(`partners.lastest`) },
  ];
  const { keyword, category } = location.state || {
    keyword: "",
    category: "",
  };

  const changeViewState = (e) => {
    const viewMode = e.target.name;
    setViewState(`${viewMode}_view_clicked`);
  };

  const changeSort = (e) => {
    const sort = e.target.value;

    const params = {
      category: category,
      keyword: keyword,
      sort: sort,
    };

    navigate(`/partners`, {
      state: params,
    });
  };

  return (
    <>
      <div className={styles.partners_wrapper}>
        <div className={styles.opt_wrapper}>
          <Select
            placeholder={t(`partners.popular`)}
            optList={filterList}
            onChange={changeSort}
          />
          <div className={styles.view_wrapper}>
            <img
              name="list"
              src={viewState === "list_view_clicked" ? list_clicked : list}
              onClick={changeViewState}
              alt="리스트 보기"
            />
            <img
              name="grid"
              src={viewState === "grid_view_clicked" ? grid_clicked : grid}
              onClick={changeViewState}
              alt="그리드 보기"
            />
            <img
              name="map"
              src={viewState === "map_view_clicked" ? map_clicked : map}
              onClick={changeViewState}
              alt="지도 보기"
            />
          </div>
        </div>
        <div className={styles.content_wrapper}>
          <div
            className={styles[`layout_wrapper_${viewState}`]}
            style={{
              gridTemplateColumns: isMobile
                ? "repeat(1, 1fr)"
                : "repeat(3, 1fr)",
            }}
          >
            {partnerList.length === 0 ? (
              <div className={styles.no_result}>
                <span>'{keyword}'</span> &nbsp; {t(`partners.no_result`)}
              </div>
            ) : (
              <>
                {partnerList.map((partner) =>
                  viewState === "list_view_clicked" ? (
                    <ListViewMode key={partner.id} partner={partner} />
                  ) : viewState === "grid_view_clicked" ? (
                    <GridViewMode key={partner.id} partner={partner} />
                  ) : null
                )}
                {viewState === "map_view_clicked" && (
                  <GoogleMap locations={partnerList} zoom={8} mapsize={50} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalList;
