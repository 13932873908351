import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import i18n from "../../locales/i18n";
import styles from "./Popup.module.scss";
import Input from "../Input/Input";
import Label from "../Input/Label";
import TextArea from "../Input/Textarea";
import Button from "../Button/Button";
import BasicAPI from "../../API/BasicAPI";
import closeBtn from "../../Assets/Images/close_icon.png";
import { CheckEmail } from "../../Validation/Validations";

const Popup = ({ setPopupOpened, partnerID }) => {
  const { t } = useTranslation();

  const [formContent, setFormContent] = useState({
    partner: partnerID,
    name: "",
    email: "",
    title: "",
    message: "",
  });

  const onChangeForm = (e) => {
    const inputData = e.target;
    setFormContent((prev) => ({ ...prev, [inputData.name]: inputData.value }));
  };

  const submitForm = () => {
    if (formContent.name === "") {
      alert(t(`popup.name_placeholder`));
      return;
    }
    if (formContent.email === "") {
      alert(t(`popup.email_placeholder`));
      return;
    }
    if (formContent.title === "") {
      alert(t(`popup.inquiry_subject_plcaeholder`));
      return;
    }
    if (formContent.message === "") {
      alert(t(`popup.inquiry_placeholder`));
      return;
    }
    if (!CheckEmail(formContent.email)) {
      alert(t(`popup.invalid_email`));
      return;
    }

    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/partner/client/contact/`,
      method: "POST",
      data: formContent,
      success: () => {
        alert(t(`popup.submit_success`));
        closePopup();
      },
      fail: (res) => console.error(res),
    });
  };

  const closePopup = () => {
    setPopupOpened(false);
    document.documentElement.style.overflow = "scroll";
  };

  return (
    <div
      className={styles.contact_popup}
      style={{ top: window.scrollY, left: 0 }}
    >
      <div className={styles.popup_window}>
        <div className={styles.close_btn}>
          <img src={closeBtn} alt="팝업창 닫기" onClick={closePopup} />
        </div>
        <div className={styles.form_wrapper}>
          <div className={styles.title}>{t(`partners.inquire`)}</div>
          <form>
            <div className={styles.input_wrapper}>
              <Label label={t(`popup.name`)} />
              <Input
                name="name"
                value={formContent.name}
                placeholder={t(`popup.name_placeholder`)}
                onChange={onChangeForm}
              />
            </div>
            <div className={styles.input_wrapper}>
              <Label label={t(`popup.email`)} />
              <Input
                name="email"
                value={formContent.email}
                placeholder={t(`popup.email_placeholder`)}
                onChange={onChangeForm}
              />
            </div>
            <div className={styles.input_wrapper}>
              <Label label={t(`popup.inquiry_subject`)} />
              <Input
                name="title"
                value={formContent.title}
                placeholder={t(`popup.inquiry_subject_plcaeholder`)}
                onChange={onChangeForm}
              />
              <TextArea
                name="message"
                rows="10"
                placeholder={t(`popup.inquiry_placeholder`)}
                value={formContent.message}
                onChange={onChangeForm}
              />
            </div>
          </form>
          <div className={styles.button_wrapper}>
            <Button type="blue" text={t(`popup.submit`)} onClick={submitForm} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
