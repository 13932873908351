import { Link } from "react-router-dom";
import styles from "./Table.module.scss";

const Table = ({ category, tableHeader, tableContent, cellWidth }) => {
  return (
    <div className={styles.table}>
      {tableHeader && (
        <div
          className={styles.table_header}
          style={{ gridTemplateColumns: cellWidth }}
        >
          {tableHeader.map((item, index) => (
            <div className={styles.cell} key={index}>
              {item}
            </div>
          ))}
        </div>
      )}
      <div className={styles.table_content}>
        {tableContent.map((item, index) => (
          <Link
            to={`/${category}/${item.id}`}
            className={styles.table_row}
            key={item.id}
            style={{ gridTemplateColumns: cellWidth }}
          >
            <div className={styles.cell}>{index + 1}</div>
            {item.title && (
              <div className={styles.fixed_cell}>{item.title}</div>
            )}
            {item.created_at && (
              <div className={styles.cell}>{item.created_at.slice(2, 10)}</div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Table;
