import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MobileContext } from "../../providers/MobileContext";
import { useTranslation } from "react-i18next";
import { CheckWordLength } from "../../Validation/Validations";
import "../../locales/i18n";
import i18n from "../../locales/i18n";
import styles from "./RegisterPartner.module.scss";
import Layout from "../../Components/Layout/Layout";
import Label from "../../Components/Input/Label";
import Input from "../../Components/Input/Input";
import BasicAPI from "../../API/BasicAPI";
import Select from "../../Components/Input/Select";
import Wrapper from "../../Components/Layout/Wrapper";
import right_arrow from "../../Assets/Images/arrow_right.png";
import remove_icon from "../../Assets/Images/delete_btn.png";
import Form from "../../Components/Form/Form";
import Warning from "../../Components/Input/Warning";

const RegisterPartnerPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useContext(MobileContext);
  const currYear = new Date().getFullYear().toString().slice(2, 4);
  const [formData, setFormData] = useState({
    company_name: "",
    category: [],
    card_number: "",
    business_number: "",
    expiration_month: "",
    expiration_year: "",
    card_password: "",
  });
  const [categoryList, setCategoryList] = useState({
    lv_1: [],
    lv_2: [],
    lv_3: [],
  });
  const [selectedCategory, setSelectedCategory] = useState({
    lv_1: "",
    lv_2: "",
    lv_3: "",
  });
  const [isValidCardNum, setIsValidCardNum] = useState(true);
  const [isValidBusinessNum, setIsValidBusinessNum] = useState(true);

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "card_number") {
      setIsValidCardNum(CheckWordLength(value, 13));
      return;
    }

    if (name === "business_number") {
      setIsValidBusinessNum(CheckWordLength(value, 10));
      return;
    }
  };

  const addCategory = () => {
    if (selectedCategory.lv_1 === "") {
      alert("카테고리를 선택해주세요.");
      return;
    }
    //추가 버튼을 클릭하면 선택된 optionlist를 formData에 추가
    setFormData((prev) => ({
      ...prev,
      category: [
        ...prev.category,
        {
          lv_1: selectedCategory.lv_1,
          lv_2: selectedCategory.lv_2,
          lv_3: selectedCategory.lv_3,
        },
      ],
    }));
    //선택한 리스트 초기화, selectbox 초기화
    setSelectedCategory({
      lv_1: selectedCategory.lv_1,
      lv_2: "",
      lv_3: "",
    });
    setCategoryList({
      lv_1: categoryList.lv_1,
      lv_2: [],
      lv_3: [],
    });
  };

  const removeCategory = (indexToRemove) => {
    const newCategoryList = formData.category.filter(
      (_, index) => index !== indexToRemove
    );

    setFormData((prev) => ({
      ...prev,
      category: newCategoryList,
    }));
  };

  const makeDateList = (startNum, endNum) => {
    let list = [];

    for (let i = startNum; i <= endNum; i++) {
      list.push({
        id: i,
        name: i < 10 ? `0${i}` : i.toString(),
      });
    }

    return list;
  };

  const onChangeOpt = (e) => {
    const { name, value } = e.target;
    const nextList = `lv_${Number(name.slice(3, 4)) + 1}`;
    const categoryID = categoryList[name].filter(
      (item) => item.name === value
    )[0].id;

    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/partner/categories/${categoryID}/sub`,
      method: "GET",
      success: (res) => {
        setCategoryList((prev) => ({
          ...prev,
          [nextList]: res.data,
        }));
      },
      fail: (err) => console.error(err),
    });

    setSelectedCategory((prev) => ({ ...prev, [name]: value }));
  };

  const submitForm = () => {
    if (formData.company_name === "") {
      alert(t(`register.hospital_name_placeholder`));
      return;
    }

    if (formData.category.length === 0) {
      alert(t(`register.category_alert`));
      return;
    }

    if (formData.card_number === "") {
      alert(t(`register.card_number_description`));
      return;
    }

    if (!isValidCardNum) {
      alert(t(`register.card_number_description`));
      return;
    }

    if (formData.business_number === "") {
      alert(t(`register.business_number_description`));
      return;
    }

    if (!isValidBusinessNum) {
      alert(t(`register.business_number_description`));
      return;
    }

    if (formData.expiration_month === "" || formData.expiration_year === "") {
      alert(t(`register.expiration_date_alert`));
      return;
    }

    if (formData.card_password === "") {
      alert(t(`register.password_alert`));
      return;
    }

    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/partner/provisional/`,
      method: "POST",
      data: formData,
      success: () => {
        alert(t(`register.pre-registraion`));
        navigate("/");
      },
      fail: (res) => console.error(res),
    });
  };

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/partner/categories/major/`,
      method: "GET",
      success: (res) => setCategoryList({ lv_1: res.data }),
      fail: (err) => console.error(err),
    });
  }, []);

  return (
    <Layout>
      <div
        style={{
          backgroundColor: isMobile ? "white" : "#f7f8fb",
        }}
      >
        <Wrapper>
          <Form
            title={t(`header.enroll`)}
            btnText={t(`register.submit`)}
            onClick={submitForm}
          >
            <div className={styles.input_wrapper}>
              <Label label={t(`register.hospital_name`)} />
              <Input
                name="company_name"
                value={formData.company_name}
                onChange={onChangeInput}
                placeholder={t(`register.hospital_name_placeholder`)}
              />
            </div>
            {categoryList && (
              <div className={styles.input_wrapper}>
                <div className={styles.label_wrapper}>
                  <Label label={t(`register.category`)} />
                  <div className={styles.add_category} onClick={addCategory}>
                    <span>+</span>
                    <p>{t(`register.add`)}</p>
                  </div>
                </div>
                <div className={styles.flex_wrapper}>
                  <Select
                    name="lv_1"
                    placeholder={t(`register.main_category`)}
                    optList={categoryList.lv_1}
                    onChange={onChangeOpt}
                  />
                  <div className={styles.line}></div>
                  <Select
                    name="lv_2"
                    placeholder={t(`register.sub_category`)}
                    optList={categoryList.lv_2 ? categoryList.lv_2 : []}
                    onChange={onChangeOpt}
                  />
                  <div className={styles.line}></div>
                  <Select
                    name="lv_3"
                    placeholder={t(`register.third_category`)}
                    optList={categoryList.lv_3 ? categoryList.lv_3 : []}
                    onChange={onChangeOpt}
                  />
                  <div
                    className={styles.line}
                    style={{ backgroundColor: "transparent" }}
                  ></div>
                </div>
                <div className={styles.list_wrapper}>
                  {formData.category.map((category, index) => {
                    return (
                      <div className={styles.list_item} key={index}>
                        <span className={styles.item_text}>
                          {category.lv_1}
                        </span>
                        {category.lv_2 && (
                          <>
                            <img src={right_arrow} />
                            <span className={styles.item_text}>
                              {category.lv_2}
                            </span>
                          </>
                        )}
                        {category.lv_3 && (
                          <>
                            <img src={right_arrow} />
                            <span className={styles.item_text}>
                              {category.lv_3}
                            </span>
                          </>
                        )}
                        <img
                          src={remove_icon}
                          alt="카테고리 삭제"
                          className={styles.remove_icon}
                          onClick={() => removeCategory(index)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className={styles.input_wrapper}>
              <Label label={t(`register.payment`)} />
              <p>{t(`register.card_number`)}</p>
              <Input
                type="number"
                name="card_number"
                maxLength={13}
                value={formData.card_number}
                onChange={onChangeInput}
                placeholder={t(`register.card_number_description`)}
              />
              {!isValidCardNum && (
                <Warning message={t(`register.card_number_description`)} />
              )}
            </div>
            <div className={styles.input_wrapper}>
              <p>{t(`register.business_number`)}</p>
              <Input
                type="number"
                name="business_number"
                value={formData.business_number}
                maxLength={10}
                onChange={onChangeInput}
                placeholder={t(`register.business_number_description`)}
              />
              {!isValidBusinessNum && (
                <Warning message={t(`register.business_number_alert`)} />
              )}
            </div>
            <div className={styles.input_wrapper}>
              <p>{t(`register.expiration_date`)}</p>
              <div className={styles.flex_wrapper}>
                <Select
                  name="expiration_month"
                  placeholder={t(`register.month`)}
                  optList={makeDateList(1, 12)}
                  onChange={onChangeInput}
                />
                <div className={styles.line}></div>
                <Select
                  name="expiration_year"
                  placeholder={t(`register.year`)}
                  optList={makeDateList(currYear, 99)}
                  onChange={onChangeInput}
                />
              </div>
            </div>
            <div className={styles.input_wrapper}>
              <p>{t(`register.password`)}</p>
              <div className={styles.circle_wrapper}>
                <Input
                  type="number"
                  name="card_password"
                  value={formData.card_password}
                  maxLength={2}
                  onChange={onChangeInput}
                  placeholder={t(`register.password_2`)}
                />
                <div className={styles.circle}></div>
                <div className={styles.circle}></div>
              </div>
            </div>
          </Form>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default RegisterPartnerPage;
