import { useContext, useEffect, useState } from "react";
import { MobileContext } from "../../../../providers/MobileContext";
import { Link } from "react-router-dom";
import styles from "../PopularNews.module.scss";
import BasicAPI from "../../../../API/BasicAPI";

const PopularArticle = () => {
  const isMobile = useContext(MobileContext);
  const [popularArticleList, setPopularArticleList] = useState([]);

  /* 인기 기사 불러오기 */
  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/article/articles/popular/`,
      method: "GET",
      success: (res) => setPopularArticleList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <div
      className={styles.rank_wrapper}
      style={{ width: isMobile ? "100%" : "45rem" }}
    >
      {popularArticleList &&
        popularArticleList.map((item, index) => (
          <Link
            to={`/article/articles/${item.id}`}
            key={item.id}
            className={styles.rank_item}
          >
            <div className={styles.rank_num}>{index + 1}</div>
            <div className={styles.rank_article_wrapper}>
              <div className={styles.article_title}>{item.title}</div>
            </div>
          </Link>
        ))}
    </div>
  );
};

export default PopularArticle;
