import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./SubPage.module.scss";
import BasicAPI from "../../API/BasicAPI";
import Button from "../../Components/Button/Button";
import ScrollTopButton from "../../Components/Button/ScrollTopButton";
import Layout from "../../Components/Layout/Layout";
import Wrapper from "../../Components/Layout/Wrapper";
import Search from "../../Components/Search/Search";
import SwiperSlider from "../../Components/Swiper/SwiperSlider";

/* image */
import writer_icon from "../../Assets/Images/writer.png";
import date_icon from "../../Assets/Images/date.png";

const SubPage = ({ search = false, url }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [content, setContent] = useState([]);

  useEffect(() => {
    BasicAPI({
      url: url,
      success: (res) => setContent(res),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <Layout>
      {search && <Search />}
      <Wrapper>
        {content.length !== 0 && (
          <div className={styles.article_wrapper}>
            <div className={styles.title_wrapper}>
              {content.column_series && (
                <div className={styles.series_name}>
                  {content.column_series.title}
                </div>
              )}
              <div className={styles.title}>{content.title}</div>
              <div className={styles.info_wrapper}>
                {content.reporter && (
                  <div className={styles.writer_wrapper}>
                    <img src={writer_icon} alt="기자명" />
                    {content.reporter}
                  </div>
                )}
                {content.column_writer && (
                  <div className={styles.writer_wrapper}>
                    <img src={writer_icon} alt="작성자명" />
                    {content.column_writer.name}
                  </div>
                )}
                <div className={styles.date_wrapper}>
                  <img src={date_icon} alt="작성날짜" />
                  <div className={styles.date}>
                    {content.created_at.slice(2, 10)}
                  </div>
                </div>
              </div>
            </div>
            {content.column_writer && (
              <div className={styles.column_writer_wrapper}>
                <img src={content.column_writer.thumbnail} alt="칼럼 기고자" />
              </div>
            )}
            {pathname.includes("cardnews") ? (
              <div className={styles.swiper_wrapper}>
                <SwiperSlider
                  swiperList={content.files}
                  hideNavigation={true}
                  sliderHeight={50}
                />
              </div>
            ) : (
              <div
                className={styles.text}
                style={{ fontSize: "initial" }}
                dangerouslySetInnerHTML={{
                  __html: content.content,
                }}
              ></div>
            )}
            {content.ad_banner && content.ad_banner.length !== 0 && (
              <SwiperSlider
                swiperList={content.ad_banner}
                sliderHeight={30}
                borderRadius
                margin="4rem 0 0 0"
              />
            )}
            <div className={styles.btn_wrapper}>
              <Button
                type="blue"
                text={t(`article.back`)}
                onClick={() => navigate(-1)}
              />
            </div>
          </div>
        )}
      </Wrapper>
      <ScrollTopButton />
    </Layout>
  );
};

export default SubPage;
