import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../Content.module.scss";
import BasicAPI from "../../../../API/BasicAPI";

const EventContent = ({ isMobile, movePage }) => {
  const { t } = useTranslation();
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/board/?category=event`,
      success: (res) => setEventList(res.data),
      fail: (res) => console.error(res),
    });
  }, []);

  return (
    <div
      className={styles.event}
      style={{ width: isMobile ? "100%" : "46rem" }}
    >
      <div className={styles.title_wrapper}>
        <div className={styles.title}>{t`header.event`}</div>
        <Link className={styles.more_btn} onClick={(e) => movePage(e, "event")}>
          {t`main.more`}
        </Link>
      </div>
      <div className={styles.board_wrapper}>
        {eventList.map(
          (item, index) =>
            index < 5 && (
              <Link
                key={item.id}
                to={`/event/${item.id}`}
                className={styles.board}
              >
                <div className={styles.thumbnail}>
                  <img src={item.thumbnail} alt="행사 게시글 썸네일" />
                </div>
                <div className={styles.text}>
                  <div className={styles.title}>{item.title}</div>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
};

export default EventContent;
