import { useContext, useState } from "react";
import { MobileContext } from "../../providers/MobileContext";
import styles from "./CategorySearch.module.scss";
import search_icon from "../../Assets/Images/search.png";
import BasicAPI from "../../API/BasicAPI";

const CategorySearch = ({
  commonCategory,
  category = "",
  filterList,
  placeholder = "키워드를 입력해주세요.",
  setContentList,
}) => {
  const isMobile = useContext(MobileContext);
  const [searchWord, setSearchWord] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("title");

  const handleSearchInput = (e) => {
    setSearchWord(e.target.value);
  };

  const handleFilterSelect = (e) => {
    setSelectedFilter(e.target.value);
  };

  const searchKeyword = () => {
    BasicAPI({
      url: `${process.env.REACT_APP_SERVICE_URL}/${commonCategory}/?${
        category && `category=${category}&`
      }${selectedFilter}=${searchWord}`,
      success: (res) => setContentList(res.data),
      fail: (res) => console.error(res),
    });
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.search_wrapper}
        style={{
          width: isMobile ? "100%" : "unset",
          marginTop: isMobile ? "2rem" : "0",
        }}
      >
        {filterList && (
          <select onChange={handleFilterSelect}>
            {filterList.map((option, index) => (
              <option key={index} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        )}
        <input
          value={searchWord}
          onChange={handleSearchInput}
          placeholder={placeholder}
          style={{ width: isMobile ? "100%" : "40rem" }}
          onKeyDown={(e) => e.key === "Enter" && searchKeyword()}
        />
        <div className={styles.submit_btn} onClick={searchKeyword}>
          <img src={search_icon} alt="검색" />
        </div>
      </div>
    </div>
  );
};

export default CategorySearch;
