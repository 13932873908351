import Wrapper from "../Layout/Wrapper";
import styles from "./Navigation.module.scss";

const Navigation = ({ navList, scrollToSection, isMobile }) => {
  return (
    <div className={styles.nav_wrapper}>
      <Wrapper>
        <div
          className={styles.flex_wrapper}
          style={{
            columnGap: isMobile ? 0 : "6rem",
            justifyContent: isMobile ? "space-around" : "unset",
          }}
        >
          {Object.keys(navList).map(
            (key) =>
              navList[key] && (
                <div
                  key={key}
                  className={styles.nav_item}
                  onClick={() => scrollToSection(key)}
                >
                  {key}
                </div>
              )
          )}
        </div>
      </Wrapper>
    </div>
  );
};

export default Navigation;
