import { useState } from "react";
import styles from "./Button.module.scss";
import scroll_top_btn from "../../Assets/Images/scrolltop_btn.png";

const ScrollTopButton = () => {
  const [scrollY, setScrollY] = useState("");

  const checkScrollY = () => setScrollY(window.scrollY);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  window.addEventListener("scroll", checkScrollY);

  return (
    scrollY >= 900 && (
      <div className={styles.scroll_top_btn} onClick={scrollToTop}>
        <img src={scroll_top_btn} alt="페이지 상단으로 이동" />
      </div>
    )
  );
};
export default ScrollTopButton;
