const Textarea = ({ name, rows, placeholder, value, onChange }) => {
  return (
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      rows={rows}
      placeholder={placeholder}
    />
  );
};

export default Textarea;
