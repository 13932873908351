import { useEffect, useState } from "react";
import styles from "./VideoList.module.scss";

const VideoList = ({ isMobile, videoList }) => {
  const [newVideoList, setNewVideoList] = useState([]);

  useEffect(() => {
    if (isMobile) {
      if (videoList.length < 2) {
        setNewVideoList(videoList[0]);
      } else {
        setNewVideoList([videoList[0], videoList[1]]);
      }
    } else {
      setNewVideoList(videoList);
    }
  }, [videoList, isMobile]);

  return (
    newVideoList.length !== 0 && (
      <div className={styles.video_list_wrapper}>
        <div className={styles.sub_title}>동영상</div>
        <div
          className={styles.video_list}
          style={{ gridTemplateColumns: isMobile ? "unset" : "repeat(3, 1fr)" }}
        >
          {newVideoList.map((video) => (
            <a
              href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
              target="_blank"
              rel="noreferrer"
              className={styles.video_wrapper}
              key={video.id.videoId}
            >
              <p>{video.videoId}</p>
              <img
                src={video.snippet.thumbnails.medium.url}
                alt="유튜브 영상 썸네일"
              />
              <div className={styles.video_title}>{video.snippet.title}</div>
              <div className={styles.video_date}>
                {video.snippet.publishedAt.slice(0, 10)}
              </div>
            </a>
          ))}
        </div>
      </div>
    )
  );
};

export default VideoList;
