import styles from "./Input.module.scss";

const Select = ({ placeholder, optList, name, onChange }) => {
  const handleChange = (e) => onChange(e.target.value);

  return (
    <div className={styles.input_wrapper}>
      <select
        name={name && name}
        onChange={name === "language" ? handleChange : onChange}
      >
        <option hidden>{placeholder}</option>
        {optList.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
