import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div style={{ minHeight: "calc(100vh - 43rem)" }}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
